// Dependencies imports
import React from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import "react-circular-progressbar/dist/styles.css";

// Local imports
import InfoIcon from "../../../../Assets/icons/infoicon.png";
import UnderTraining from "../../../../Assets/icons/undertraining.gif";

// Style imports
import Card from "./cardstyle.module.scss";

const HandwashTrackingCard = () => {
  return (
    <div className={Card.mainbigcontainer}>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Handwash Tracking</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      {/* <div className={Card.middleholder}>
        <div className={Card.leftbigholder}>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>20s Rule Followed:</h5>
            <h5 className={Card.datavalueyellow}>21</h5>
          </div>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>Times washed:</h5>
            <h5 className={Card.datavaluegreen}>37</h5>
          </div>

          <br />
          <button className={Card.learnmore}>Learn More</button>
        </div>
        <div className={Card.rightbigholder}>
          <SemiCircleProgressBar
            percentage={45}
            showPercentValue
            diameter={125}
            stroke="#2BA65A"
          />
          <h4 className={Card.textbelowprogress}>HYGIENE SCORE</h4>
        </div>
      </div> */}
      <div className={Card.underprogressholder}>
        <img
          src={UnderTraining}
          className={Card.undertraininggif}
          alt="undertraininggif"
        />
        <h4 className={Card.underprogress}>Training under progress</h4>
      </div>
    </div>
  );
};

export default HandwashTrackingCard;
