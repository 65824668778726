// Dependencies imports
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

// Local imports
import FootFallCard from "./footfall/footfallcard";
import CustomerConversionCard from "./customerconversioncard";
import StoreTimingsCard from "./storetimingscard";
import StoreHeadMapCard from "./storeheatmapscard";
import GenderRatioCard from "./demographics/genderratiocard";
import CustomerSatisfactionCard from "./customersatisfaction/customersatisfactioncard";
import BillingCounterUnmanned from "./billingcounterunmanned/billingcounterunmannedcard";
import EmployeePresenceCard from "./employeepresencecard";
// import { ReactComponent as BulbIcon } from "../../../../Assets/icons/yellowbulb.svg";
// import { ReactComponent as AnalyticsIcon } from "../../../../Assets/icons/analyticsicon.svg";
import CustomerTimeInsights from "./customertimeinsights/cutomertimeinsightscard";
import StoreEntranceUnmannedCard from "./storeentranceunmanned";
import CustomerUnattendedCard from "./customerunattended/customerunattendedcard";
import RoomOccupancyCard from "./roomoccupancy/roomoccupancycard";
import AgeEstimationCard from "./demographics/ageestimationcard";
import AddFeatureCard from "../hygiene/addfeature";
import { authData } from "../../../../redux/auth/authSlice";

// Style imports
import Hy from "../hygiene/hygiene.module.scss";

// Styles for carousel responsiveness
// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 1,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const Quality = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let userDetails = useSelector(authData);
  const [spin, setSpin] = useState(true);
  const [qualityFeatures, setQualityFeatures] = useState([]);
  const [hygieneCount, setHygieneCount] = useState(0);
  const [interval, setInterval] = useState("day");
  const [safetyCount, setSafetyCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    getFeatureList();
    //eslint-disable-next-line
  }, []);

  const getFeatureList = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/cameras/find/features_list/${userDetails.outlet_code}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setQualityFeatures(result.data["quality"]);
        setHygieneCount(result.data["hygiene"].length);
        setSafetyCount(result.data["safety"].length);
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong!",
          icon: "error",
          buttons: false,
        });
      });
  };

  return (
    <div className={Hy.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Hy.topbar}>
        <div className={Hy.leftbar}>
          <div
            className={
              location.pathname === "/hygiene" ? Hy.holderactive : Hy.holder
            }
            onClick={() => navigate("/hygiene")}
          >
            Hygiene ({hygieneCount})
          </div>
          <div
            className={
              location.pathname === "/quality" ? Hy.holderactive : Hy.holder
            }
            onClick={() => navigate("/quality")}
          >
            Quality ({qualityFeatures.length})
          </div>
          <div
            className={
              location.pathname === "/safety" ? Hy.holderactive : Hy.holder
            }
            onClick={() => navigate("/safety")}
          >
            Safety ({safetyCount})
          </div>
        </div>
        <div className={Hy.rightpart}>
          <select
            className={Hy.dayselect}
            value={interval}
            onChange={(e) => setInterval(e.target.value)}
          >
            <option selected value="day">
              Today
            </option>
            <option value="yesterday">Yesterday</option>
            <option value="week">Last 7 Days</option>
            <option value="month">Last 30 Days</option>
          </select>
        </div>
      </div>
      {/* <div className={Hy.summarycardsholder}>
        <div className={Hy.carouselcard}>
          <div className={Hy.summarytopbar}>
            <BulbIcon className={Hy.bulbicon} />
            <h4 className={Hy.summaryheading}>Quick Summary</h4>
          </div>
          <h5 className={Hy.quicksummaryinfo}>
            Average <span className={Hy.boldtext}>Foot Fall</span>{" "}
            <span className={Hy.improved}>increased 36%</span> Since Last week.
            Keep working on aisle rearranging.
          </h5>
        </div>
        <Carousel
          infinite={true}
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          dotListClass="custom-dot-list-style"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          className={Hy.quicksummarycards}
        >
          
          <div className={Hy.carouselcard}>
            <div className={Hy.summarytopbar}>
              <BulbIcon className={Hy.bulbicon} />
              <h4 className={Hy.summaryheading}>Quick Summary</h4>
            </div>
            <h5 className={Hy.quicksummaryinfo}>
              Average <span className={Hy.boldtext}> Foot Fall</span> Reduced
              since last week. Foot Fall was Higher than Yesterday.
            </h5>
          </div>
          <div className={Hy.carouselcard}>
            <div className={Hy.summarytopbar}>
              <BulbIcon className={Hy.bulbicon} />
              <h4 className={Hy.summaryheading}>Quick Summary</h4>
            </div>
            <h5 className={Hy.quicksummaryinfo}>
              Hygiene Index <span className={Hy.improved}>improved</span> since
              Yesterday. <span className={Hy.boldtext}>Sanitaion </span>Score
              <span className={Hy.reduced}> reduced</span> since Yesterday.
            </h5>
          </div>
        </Carousel>
        <div className={Hy.datainsights}>
          <div className={Hy.summarytopbar}>
            <AnalyticsIcon />
            <h4 className={Hy.summaryheading}>Data Insights</h4>
          </div>
          <br />
          <h3 className={Hy.quicksummaryinfo}>
            <span className={Hy.boldtext}>Hygiene Index</span> value has
            increased <span className={Hy.greentext}>0.5</span> points ince
            yesterday. And is <span className={Hy.redtext}>0.5</span> lower than
            your weekly average.
          </h3>
          <div className={Hy.buttoninsightsholder}>
            <button className={Hy.insightsbutton}>
              Yesterday: <span className={Hy.orangetext}>3.5</span>
            </button>
            <button className={Hy.insightsbutton}>
              Today: <span className={Hy.greentext}>4</span>
            </button>
          </div>
          <div className={Hy.buttoninsightsholder}>
            <button className={Hy.insightsbutton}>
              Weekly Avg: <span className={Hy.greentext}>4</span>
            </button>
            <button className={Hy.insightsbutton}>
              Montly Avg: <span className={Hy.greentext}>4.5</span>
            </button>
          </div>
        </div>
      </div> */}
      <div className={Hy.cardsholder}>
        {/* Testing cards */}
        {/* <FootFallCard />
        <CustomerConversionCard />
        <GenderRatioCard />
        <CustomerTimeInsights />
        <CustomerSatisfactionCard />
        <StoreTimingsCard />
        <StoreHeadMapCard />
        <StoreEntranceUnmannedCard />
        <EmployeePresenceCard />
        <CustomerUnattendedCard />
        <BillingCounterUnmanned />
        <RoomOccupancyCard />
        <AgeEstimationCard /> */}
        {qualityFeatures.length !== 0 ? (
          <React.Fragment>
            {qualityFeatures.includes("CI.PC") === true ? (
              <FootFallCard interval={interval} setInterval={setInterval} />
            ) : null}
            {qualityFeatures.includes("CI.GRM") === true ? (
              <GenderRatioCard interval={interval} setInterval={setInterval} />
            ) : null}
            {qualityFeatures.includes("CI.CTI") ? (
              <CustomerTimeInsights
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {qualityFeatures.includes("CI.CSI") ? (
              <CustomerSatisfactionCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {qualityFeatures.includes("CI.CU") ? (
              <CustomerUnattendedCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {qualityFeatures.includes("OCT.MBC") ? (
              <BillingCounterUnmanned
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {qualityFeatures.includes("WI.RO") ? (
              <RoomOccupancyCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {qualityFeatures.includes("CI.CCM") === true ? (
              <CustomerConversionCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {qualityFeatures.includes("CI.AE") ? (
              <AgeEstimationCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}

            {qualityFeatures.includes("SEPM.OCT") ? (
              <StoreTimingsCard interval={interval} setInterval={setInterval} />
            ) : null}
            {qualityFeatures.includes("CI.SH") ? (
              <StoreHeadMapCard interval={interval} setInterval={setInterval} />
            ) : null}
            {qualityFeatures.includes("SEPM.SEU") ? (
              <StoreEntranceUnmannedCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {qualityFeatures.includes("SEPM.EP") ? (
              <EmployeePresenceCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}

            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/selectcameras")}
            >
              <AddFeatureCard />
            </div>
          </React.Fragment>
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/selectcameras")}
          >
            <AddFeatureCard />
          </div>
        )}
      </div>
    </div>
  );
};

export default Quality;
