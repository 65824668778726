// Dependencies imports
import React from "react";

// Local imports
import SideNav from "../sidenav/sidenav";
import Header from "../headers/header";
import Quality from "../Components/Screens/DashboardScreens/quality/quality";

// Style imports
import Lay from "./layout.module.scss";

const QualityLayout = () => {
  return (
    <div className={Lay.maincontainer}>
      <Header />
      <div className={Lay.nextcontainer}>
        <SideNav />
        <Quality />
      </div>
    </div>
  );
};

export default QualityLayout;
