// Dependencies imports
import React, { useState, useEffect } from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";

// Local imports
import InfoIcon from "../../../../../Assets/icons/infoicon.png";
import BillingCounterUnmannedModal from "./billingunmannedmodal";
import { authData } from "../../../../../redux/auth/authSlice";

// Style imports
import Card from "../../hygiene/cardstyle.module.scss";
import "react-circular-progressbar/dist/styles.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const BillingCounterUnmanned = (props) => {
  const { interval, setInterval } = props;
  const [open, setOpen] = useState(false);
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const [unmannedCount, setUnmannedCount] = useState(0);
  const [lastUnmanned, setLastUnmanned] = useState();
  const [complianceScore, setComplianceScore] = useState();

  useEffect(() => {
    getCardData();
    //eslint-disable-next-line
  }, [interval]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getCardData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/counter_manned_check/aggregate/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setUnmannedCount(result.data["result"][0]["unmanned_count"]);
        setLastUnmanned(result.data["result"][0]["last_unmanned"]);
        getComplianceScore();
      })
      .catch(function (error) {});
  };

  const getComplianceScore = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/counter_manned_check/aggregate_compliance_score/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setComplianceScore((result.data["result"] * 100).toFixed(2));
      })
      .catch(function (error) {});
  };

  return (
    <div className={Card.mainbigcontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <BillingCounterUnmannedModal
            handleClose={handleClose}
            unmannedCount={unmannedCount}
            lastUnmanned={lastUnmanned}
            interval={interval}
            setInterval={setInterval}
          />
        </Fade>
      </Modal>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Unmanned Area</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      <div className={Card.middleholder}>
        <div className={Card.leftbigholder}>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>Count:</h5>
            <h5 className={Card.datavalueblue}>{unmannedCount}</h5>
          </div>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>Last Event:</h5>
            <h5 className={Card.datavalueblue}>
              {lastUnmanned !== null
                ? moment.unix(lastUnmanned).format("LT")
                : "-"}
            </h5>
          </div>
          <br />
          <button className={Card.learnmore} onClick={() => handleOpen()}>
            Learn More
          </button>
        </div>
        <div className={Card.rightbigholder}>
          <SemiCircleProgressBar
            percentage={complianceScore}
            showPercentValue
            diameter={120}
            stroke={
              complianceScore > 60
                ? "#2BA65A"
                : complianceScore < 30
                ? "#EE4143"
                : "#FBCD57"
            }
          />
          <h4 className={Card.textbelowprogress}>COMPLIANCE SCORE</h4>
        </div>
      </div>
    </div>
  );
};

export default BillingCounterUnmanned;
