//Dependencies imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import { ReactComponent as Celebration } from "../../../Assets/images/celebration.svg";
import { stateUpdate, authData } from "../../../redux/auth/authSlice";

// Style imports
import Sync from "./featuredescription.module.scss";
import Syn from "./syncdemo.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const SyncDemo = (props) => {
  const { handleCloseSyncDemo } = props;
  const navigate = useNavigate();
  const [spin, setSpin] = useState(false);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const classes = useStyles();

  const getOnboardingState = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/onboard/onboarding_state/${userDetails.email.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setSpin(false);
        console.log(result.data);
        let userData = { ...userDetails };
        userData.onboarding_state = result.data;
        dispatch(stateUpdate(userData));
        handleCloseSyncDemo();
        navigate("/hygiene");
        setSpin(false);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        setSpin(false);
      });
  };

  return (
    <div className={Syn.mainpopup}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Syn.buttonholder}>
        <button
          className={Syn.primarybutton}
          onClick={() => {
            handleCloseSyncDemo();
            navigate("/selectfeatures");
          }}
        >
          Add another feature to Same Camera
        </button>
        <button
          className={Syn.secondarybutton}
          onClick={() => {
            handleCloseSyncDemo();
            navigate("/selectcameras");
          }}
        >
          Select Another Camera
        </button>
      </div>
      <div className={Sync.syncimageholder}>
        <Celebration className={Sync.syncimage} />
      </div>

      <h4 className={Syn.heading}>
        Done adding features to your Cameras? <br />
        Let's go see your dashboard.
      </h4>
      <div className={Syn.bottombutton}>
        <button
          className={Syn.primarybutton}
          onClick={() => {
            getOnboardingState();
          }}
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default SyncDemo;
