// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import InfoIcon from "../../../../../Assets/icons/infoicon.png";
import { ReactComponent as CustomerTimeIcon } from "../../../../../Assets/icons/customertime.svg";
import CustomerTimeInsightsModal from "./customertimeinsightmodal";
import { authData } from "../../../../../redux/auth/authSlice";

// Style imports
import Card from "../../hygiene/cardstyle.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const CustomerTimeInsights = (props) => {
  const { interval, setInterval } = props;
  let userDetails = useSelector(authData);
  const [open, setOpen] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getCardData();
    //eslint-disable-next-line
  }, [interval]);

  const getCardData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/customer_time_insights/aggregate/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setCustomerData(result.data["result"]);
      })
      .catch(function (error) {});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const returnTime = (params) => {
    var decimalTime = params;
    decimalTime = decimalTime * 60;
    var hours = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;
    var minutes = Math.floor(decimalTime / 60);
    decimalTime = decimalTime - minutes * 60;
    var seconds = Math.round(decimalTime);
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return (
      <h3 className={Card.datavalueblue}>
        {hours + ":" + minutes + ":" + seconds}
      </h3>
    );
  };

  return (
    <div className={Card.mainbigcontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <CustomerTimeInsightsModal
            handleClose={handleClose}
            interval={interval}
            setInterval={setInterval}
            customerData={customerData}
          />
        </Fade>
      </Modal>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Customer Time Insights</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      <div className={Card.middleholder}>
        <div className={Card.leftbigholder}>
          {customerData !== null ? (
            customerData.length !== 0 ? (
              customerData.slice(0, 2).map((value, i) => {
                return (
                  <div className={Card.eventsholder} key={i}>
                    <h5 className={Card.featuredetails}>
                      {value.roi_name !== null
                        ? value.roi_name.length > 15
                          ? `${value.roi_name.substring(0, 15)}... `
                          : value.roi_name
                        : null}
                      <span className={Card.tooltiptext}>{value.roi_name}</span>
                    </h5>
                    <h3 className={Card.datavalueblue}>
                      {returnTime(value.timespent)}
                    </h3>
                  </div>
                );
              })
            ) : (
              <div className={Card.eventsholder}>
                <h5 className={Card.featuredetails}>Area 0:</h5>
                <h3 className={Card.datavalueblue}>0 Min</h3>
              </div>
            )
          ) : null}
          <br />
          <button className={Card.learnmore} onClick={() => handleOpen()}>
            Learn More
          </button>
        </div>
        <div className={Card.rightholder}>
          <div className={Card.iconholder}>
            <CustomerTimeIcon className={Card.icon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTimeInsights;
