// Dependencies imports
import React from "react";

// Local imports
import { ReactComponent as AddCircle } from "../../../../Assets/icons/addcircleblack.svg";

// Style imports
import Card from "./cardstyle.module.scss";

const AddFeatureCard = () => {
  return (
    <div className={Card.mainaddcontainer}>
      <AddCircle className={Card.addfeatureicon} />
      <h3 className={Card.addfeaturetext}>Add Feature</h3>
    </div>
  );
};

export default AddFeatureCard;
