import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props) => {
  const { labels, timeSights } = props;

  const options = {
    scales: {
      y: {
        title: { display: true, text: "Time Spent in mins" },
      },
      x: {
        title: {
          display: true,
          text: "Hour",
        },
      },
    },
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Hourly Average",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Time Spent",
        data: timeSights,
        borderColor: "#226BF6",
        backgroundColor: "#226BF6",
      },
    ],
  };

  return <Bar height={70} width={200} options={options} data={data} />;
};

export default BarChart;
