// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import InfoIcon from "../../../../../Assets/icons/infoicon.png";
import CustomerUnattended from "../../../../../Assets/images/customerunattended.png";
import OpenIcon from "../../../../../Assets/images/openicon.png";
import { authData } from "../../../../../redux/auth/authSlice";
import CustomerUnattendedModal from "./customerunattendedmodal";
import NoImage from "../../../../../Assets/images/noimage.png";

// Style imports
import Card from "../../hygiene/cardstyle.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const CustomerUnattendedCard = (props) => {
  const { interval, setInterval } = props;
  let userDetails = useSelector(authData);
  const [count, setCount] = useState(0);
  const [lastEvent, setLastEvent] = useState();
  const classes = useStyles();
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getCardData();
    //eslint-disable-next-line
  }, [interval]);

  const getCardData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/customer_attended/aggregate/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("customer unattended response", result.data);
        setCount(result.data.result[0]["unattended_count"]);
        setLastEvent(result.data.result[0]["last_unattended"]);
        setImage(result.data.result[0]["screenshot"]);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={Card.mainbigcontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <CustomerUnattendedModal
            handleClose={handleClose}
            count={count}
            lastEvent={lastEvent}
            interval={interval}
            setInterval={setInterval}
          />
        </Fade>
      </Modal>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Customer Unattended</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      <div className={Card.middleholder}>
        <div className={Card.leftimageholder}>
          <img
            className={Card.featureimage}
            src={image === "" || image === null ? NoImage : image}
            alt="featureimage"
          />
          <img
            className={Card.openicon}
            src={OpenIcon}
            alt="openicon"
            onClick={() => handleOpen()}
          />
        </div>
        <div className={Card.rightcardholder}>
          <div className={Card.eventsholder}>
            <h4 className={Card.featuredetails}>Count: </h4>
            <h5 className={Card.datavaluered}>{count}</h5>
          </div>
          <div className={Card.lastactivityholder}>
            <h4 className={Card.featuredetails}>Last Event: </h4>
            <h5 className={Card.datavalueblue}>
              {lastEvent !== null
                ? moment.unix(lastEvent).format("LT")
                : "--:-- --"}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerUnattendedCard;
