// Dependencies imports
import React from "react";

// Local imports
import InfoIcon from "../../../../Assets/icons/infoicon.png";
import { ReactComponent as SanitationIcon } from "../../../../Assets/icons/sanitationcard.svg";
import UnderTraining from "../../../../Assets/icons/undertraining.gif";

// Style imports
import Card from "./cardstyle.module.scss";

const SanitationCard = () => {
  return (
    <div className={Card.mainbigcontainer}>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Sanitation</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      {/* <div className={Card.middleholder}>
        <div className={Card.leftbigholder}>
          <div className={Card.eventsholder}>
            <h5 className={Card.featurescoreyellow}>3/5</h5>
          </div>
          <br />
          <button className={Card.learnmore}>Learn More</button>
        </div>
        <div className={Card.rightholder}>
          <div className={Card.iconholder}>
            <SanitationIcon className={Card.icon} />
          </div>
        </div>
      </div> */}
      <div className={Card.underprogressholder}>
        <img
          src={UnderTraining}
          className={Card.undertraininggif}
          alt="undertraininggif"
        />
        <h4 className={Card.underprogress}>Training under progress</h4>
      </div>
    </div>
  );
};

export default SanitationCard;
