// Dependencies imports
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

// Local imports
import Close from "../../../../../Assets/icons/blueicon.png";
import BarChart from "./ageestimationgraph";
import { authData } from "../../../../../redux/auth/authSlice";
import ImagePlaceholder from "../../../../../Assets/images/imageplaceholder.png";
import ImageViewModal from "../roomoccupancy/imageviewmodal";

// Style imports
import Graph from "../billingcounterunmanned/graphstyles.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme-header": {
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
      flex: 1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
    width: "100%",
  },
}));

const AgeEstimationModal = (props) => {
  const { handleClose, highestAge, highestAgeGroup, interval, setInterval } =
    props;
  const [selectedNav, setSelectedNav] = useState("graph");
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [images, setImages] = useState([]);
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const [modalImage, setModalImage] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [spin, setSpin] = useState(true);
  const [youth, setYouth] = useState(0);
  const [adult, setAdult] = useState(0);
  const [teen, setTeen] = useState(0);
  const [kid, setKid] = useState(0);
  const [middleAge, setMiddleAge] = useState(0);

  useEffect(() => {
    getGraphData();
    //eslint-disable-next-line
  }, [interval]);

  //   API to get graph and logs response
  const getGraphData = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/demographics/aggregate_list_age/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("age estimation list for graph", result.data["result"][0]);
        formatGraphData(result.data["result"][0]);
        setYouth(
          result.data["result"][0]["Youth"] !== undefined
            ? result.data["result"][0]["Youth"]
            : 0
        );
        setAdult(
          result.data["result"][0]["Adult"] !== undefined
            ? result.data["result"][0]["Adult"]
            : 0
        );
        setTeen(
          result.data["result"][0]["Teen"] !== undefined
            ? result.data["result"][0]["Teen"]
            : 0
        );
        setKid(
          result.data["result"][0]["Kid"] !== undefined
            ? result.data["result"][0]["Kid"]
            : 0
        );
        setMiddleAge(
          result.data["result"][0]["MiddleAge"] !== undefined
            ? result.data["result"][0]["MiddleAge"]
            : 0
        );

        getDataGridData();
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  //   Formating response to get graph data
  const formatGraphData = (values) => {
    let labels = [];
    let events = [];

    for (const [key, value] of Object.entries(values)) {
      labels.push(key);
      events.push(value);
    }
    console.log("labels", labels);
    console.log("events", events);
    setLabels(labels);
    setData(events);
  };

  //   API to get graph and logs response
  const getDataGridData = () => {
    console.log("entered grid data");
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/demographics/aggregate_logs/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        if (result.data["result"].length !== 0) {
          console.log("ageestimation grid result", result.data);
          tableViewConvertion(result.data["result"]);
        }
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const tableViewConvertion = (value) => {
    let trows = [];
    let timages = [];

    if (value.length > 0) {
      for (let i = value.length - 1; i >= 0; i--) {
        if (value[i]["events"].length > 0) {
          for (let k = value[i]["events"].length - 1; k >= 0; k--) {
            let obj = {};
            let tobj = {};
            tobj.screenshot = value[i]["events"][k]["screenshot"];
            tobj.duration = value[i]["events"][k]["duration"];
            tobj.timestamp = `${moment
              .unix(value[i]["events"][k]["timestamp"])
              .format("LT")}`;
            tobj.cameranumber = value[i]["roi_name"];
            obj["id"] = trows.length + 1;
            let todayDate = `${value[i]["timestamp"]["day"]}-${value[i]["timestamp"]["month"]}-${value[i]["timestamp"]["year"]}`;
            todayDate = todayDate.split("-");
            obj["timeframe"] = `${moment
              .unix(value[i]["events"][k]["timestamp"])
              .format("LLL")}`;
            obj["camera"] = value[i]["roi_name"];
            let youth = [];
            let adult = [];
            let kid = [];
            let teen = [];
            let middleage = [];
            for (let l = 0; l < value[i]["events"][k]["age"].length; l++) {
              if (value[i]["events"][k]["age"][l] === "Youth") {
                youth.push(value[i]["events"][k]["age"][l]);
              } else if (value[i]["events"][k]["age"][l] === "Adult") {
                adult.push(value[i]["events"][k]["age"][l]);
              } else if (value[i]["events"][k]["age"][l] === "Kid") {
                kid.push(value[i]["events"][k]["age"][l]);
              } else if (value[i]["events"][k]["age"][l] === "Teen") {
                teen.push(value[i]["events"][k]["age"][l]);
              } else if (value[i]["events"][k]["age"][l] === "Middle Age") {
                middleage.push(value[i]["events"][k]["age"][l]);
              }
            }

            obj["status"] = `${
              youth.length !== 0
                ? youth.length + (youth.length > 1 ? " youths," : " youth,")
                : ""
            } ${
              adult.length !== 0
                ? adult.length + (adult.length > 1 ? " adults," : " adult")
                : ""
            } ${
              kid.length !== 0
                ? kid.length + (kid.length > 1 ? " kids," : " kid,")
                : ""
            } ${
              teen.length !== 0
                ? teen.length + (teen.length > 1 ? " teens," : " teen,")
                : ""
            } ${
              middleage.length !== 0 ? middleage.length + " middle age" : ""
            }`;
            obj["image"] = value[i]["events"][k]["screenshot"];
            console.log("object", obj);
            trows.push(obj);
            timages.push(tobj);
            console.log("pushed");
          }
        }
      }
    }

    const tcolumns = [
      {
        field: "timeframe",
        headerName: "TIMEFRAME",
        flex: 1,
        headerClassName: "super-app-theme-header",
      },
      {
        field: "camera",
        headerName: "AREA NAME",
        flex: 1,
        headerClassName: "super-app-theme-header",
      },
      {
        field: "status",
        headerName: "Age Group Detected",
        flex: 1,
        headerClassName: "super-app-theme-header",
      },
      {
        field: "image",
        headerName: "IMAGES",
        flex: 0.5,
        headerClassName: "super-app-theme-header",

        renderCell: (params) => (
          <div
            className={Graph.image}
            onClick={() => {
              setModalImage(params.value);
              console.log(params.value);
              handleModalOpen();
            }}
          >
            <img
              src={ImagePlaceholder}
              alt="imageholder"
              className={Graph.imageplaceholder}
            />
          </div>
        ),
      },
    ];

    setRows(trows);
    setColumns(tcolumns);
    setImages(timages);
    setSpin(false);
  };

  const GraphComponent = () => {
    return (
      <div className={Graph.graphcontainer}>
        <div className={Graph.topcountholder}>
          <div className={Graph.dataholder}>
            <h4 className={Graph.data}>
              Larger age group count is:{" "}
              <span className={Graph.bolddata}>{highestAge}</span>
            </h4>
          </div>
          <div className={Graph.dataholder}>
            <h4 className={Graph.data}>
              Highest Age Group:{" "}
              <span className={Graph.bolddata}>{highestAgeGroup}</span>
            </h4>
          </div>
        </div>
        <div className={Graph.splitgraph}>
          <div className={Graph.leftlist}>
            <h4 className={Graph.leftlabel}>
              Youth: <span className={Graph.youthvalue}>{youth}</span>
            </h4>
            <h4 className={Graph.leftlabel}>
              Adult: <span className={Graph.adultvalue}>{adult}</span>
            </h4>
            <h4 className={Graph.leftlabel}>
              Teen: <span className={Graph.teenvalue}>{teen}</span>
            </h4>
            <h4 className={Graph.leftlabel}>
              MiddleAge:{" "}
              <span className={Graph.middleagevalue}>{middleAge}</span>
            </h4>
            <h4 className={Graph.leftlabel}>
              Kid: <span className={Graph.kidvalue}>{kid}</span>
            </h4>
          </div>
          <div className={Graph.graphholder}>
            <BarChart labels={labels} dataset={data} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={Graph.maincontainer}>
      <Modal
        open={openModal}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={openModal}>
          <ImageViewModal
            handleModalClose={handleModalClose}
            modalImage={modalImage}
          />
        </Fade>
      </Modal>
      <div className={Graph.topbar}>
        <h3 className={Graph.heading}>Age Estimation</h3>
        <div className={Graph.topnavigation}>
          <div
            className={
              selectedNav === "graph"
                ? Graph.navitemholderactive
                : Graph.navitemholder
            }
            onClick={() => setSelectedNav("graph")}
          >
            <h4 className={Graph.navitem}>Graphs</h4>
          </div>
          <div
            className={
              selectedNav === "logs"
                ? Graph.navitemholderactive
                : Graph.navitemholder
            }
            onClick={() => setSelectedNav("logs")}
          >
            <h4 className={Graph.navitem}>Logs</h4>
          </div>
          <div
            className={
              selectedNav === "images"
                ? Graph.navitemholderactive
                : Graph.navitemholder
            }
            onClick={() => setSelectedNav("images")}
          >
            <h4 className={Graph.navitem}>Images</h4>
          </div>
        </div>
        <div className={Graph.rightselection}>
          <select
            className={Graph.dayselect}
            onChange={(e) => {
              setRows([]);
              setColumns([]);
              setImages([]);
              setInterval(e.target.value);
            }}
            value={interval}
          >
            <option selected value="day">
              Today
            </option>
            <option value="yesterday">Yesterday</option>
            <option value="week">Last 7 Days</option>
            <option value="month">Last 30 Days</option>
          </select>
          <img
            src={Close}
            alt="closeimage"
            className={Graph.closeimage}
            onClick={() => handleClose()}
          />
        </div>
      </div>
      <div className={Graph.middlebar}>
        {selectedNav === "graph" ? (
          spin === false ? (
            <GraphComponent />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rect" width="100%" height={500} />{" "}
            </div>
          )
        ) : selectedNav === "logs" ? (
          spin === false ? (
            <div style={{ height: "60vh", width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                className={classes[".MuiDataGrid-root"]}
                rowHeight={100}
                disableSelectionOnClick
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rect" width="100%" height={500} />{" "}
            </div>
          )
        ) : (
          <div className={Graph.carouselholder}>
            {images.length !== 0 ? (
              <Carousel dynamicHeight={true} width={"60%"}>
                {images.slice(0, 10).map((value, i) => {
                  return (
                    <div key={i}>
                      <img
                        src={value.screenshot}
                        alt="eventimage"
                        className={Graph.eventimage}
                      />
                      <p className="legend">
                        Action: Age Estimation
                        <br />
                        Area: {value.cameranumber}
                        <br />
                        Timestamp: {value.timestamp}
                        <br />
                        Duration: {value.duration !== null
                          ? value.duration
                          : 0}{" "}
                        mins
                      </p>
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              <h4>Nothing to show here</h4>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgeEstimationModal;
