// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";

// Local imports
import ProfileImage from "../Assets/images/avatar.png";
import Logo from "../Assets/logo/logowhite.png";
import { ReactComponent as SearchIcon } from "../Assets/icons/search.svg";
import { ReactComponent as NotificationIcon } from "../Assets/icons/notificationwhite.svg";
import { ReactComponent as CalenderIcon } from "../Assets/icons/calenderwhite.svg";
import { ReactComponent as TimeIcon } from "../Assets/icons/timewhite.svg";
import { ReactComponent as LocationIcon } from "../Assets/icons/locationwhite.svg";
import { stateUpdate, authData } from "../redux/auth/authSlice";
import ProfilePopup from "../Components/widgets/profilepopup";

// Style imports
import Head from "./header.module.scss";

const Header = () => {
  let userDetails = useSelector(authData);
  const [date, setDate] = useState(new Date());
  const [profileModalShow, setProfileModalShow] = useState(false);
  const [location, setLocation] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getOutletLocation();
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const getOutletLocation = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/onboard/get_outlet_details_outlet/${userDetails.outlet_code.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("outlet location", result.data);
        setLocation(result.data["result"][0]["location"]);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
      });
  };

  function refreshClock() {
    setDate(new Date());
  }

  function Clock() {
    return <span>{moment(date).format("LT")}</span>;
  }

  return (
    <div className={Head.maintop}>
      <div className={Head.logoholder}>
        <img src={Logo} alt="logo" className={Head.logo} />
      </div>
      {profileModalShow === true ? (
        <ProfilePopup setProfileModalShow={setProfileModalShow} />
      ) : null}
      <div className={Head.leftuserdetails}>
        <img
          className={Head.profileimage}
          src={ProfileImage}
          alt="profileimage"
          onClick={() => setProfileModalShow(true)}
        />
        <div className={Head.userwelcome}>
          <h3 className={Head.welcometext}>Hello {userDetails.username},</h3>
          <h4 className={Head.belowwelcome}>Welcome to OurEye.ai</h4>
        </div>
      </div>
      {/* <div className={Head.middlesearchbar}>
        <input type="text" placeholder="Search" className={Head.searchinput} />
        <SearchIcon className={Head.searchicon} />
      </div> */}
      <div className={Head.middleholder}></div>
      <div className={Head.rightinformation}>
        {/* <NotificationIcon className={Head.icon} /> */}
        <div className={Head.iconinfoholder}>
          <TimeIcon className={Head.icon} />
          <h4 className={Head.todaytime}>{Clock()}</h4>
        </div>
        <div className={Head.iconinfoholder}>
          <CalenderIcon className={Head.icon} />
          <h4 className={Head.todaydate}>{moment(date).format("LL")}</h4>
        </div>
        <div className={Head.iconinfoholder}>
          <LocationIcon className={Head.icon} />
          <h4 className={Head.todaydate}>{location}</h4>
        </div>
      </div>
    </div>
  );
};

export default Header;
