// Dependencies imports
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

// Local imports
// import HygieneIndexCard from "./hygieneindexcard";
import SanitationCard from "./sanitationcard";
import SmokingDetectionCard from "./smokingdetectioncard";
import GlovesDetectionCard from "./glovesdetectioncard";
import HairnetDetectionCard from "./hairnetdetectioncard";
import MaskDetectionCard from "./maskdetectioncard";
import HandwashTrackingCard from "./handwashtrackingcard";
import FloorMoppingCard from "./floormoppingcard";
import AddFeatureCard from "./addfeature";
import { authData } from "../../../../redux/auth/authSlice";
// import { ReactComponent as BulbIcon } from "../../../../Assets/icons/yellowbulb.svg";
// import { ReactComponent as AnalyticsIcon } from "../../../../Assets/icons/analyticsicon.svg";

// Style imports
import Hy from "./hygiene.module.scss";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const Hygiene = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let userDetails = useSelector(authData);
  const [hygieneFeatures, setHygieneFeatures] = useState([]);
  const [safetyCount, setSafetyCount] = useState(0);
  const [spin, setSpin] = useState(true);
  const [qualityCount, setQualityCount] = useState(0);
  const [interval, setInterval] = useState("day");
  const classes = useStyles();

  useEffect(() => {
    getFeatureList();
    //eslint-disable-next-line
  }, []);

  const getFeatureList = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/cameras/find/features_list/${userDetails.outlet_code}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        let tempHygieneFeatures = [...new Set(result.data["hygiene"])];
        let tempQuliatyFeatures = [...new Set(result.data["quality"])];
        let tempSafetyFeatures = [...new Set(result.data["safety"])];
        setHygieneFeatures(tempHygieneFeatures);
        setQualityCount(tempQuliatyFeatures.length);
        setSafetyCount(tempSafetyFeatures.length);
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong!",
          icon: "error",
          buttons: false,
        });
      });
  };

  return (
    <div className={Hy.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Hy.topbar}>
        <div className={Hy.leftbar}>
          <div
            className={
              location.pathname === "/hygiene" ? Hy.holderactive : Hy.holder
            }
            onClick={() => navigate("/hygiene")}
          >
            Hygiene ({hygieneFeatures.length})
          </div>
          <div
            className={
              location.pathname === "/quality" ? Hy.holderactive : Hy.holder
            }
            onClick={() => navigate("/quality")}
          >
            Quality ({qualityCount})
          </div>
          <div
            className={
              location.pathname === "/safety" ? Hy.holderactive : Hy.holder
            }
            onClick={() => navigate("/safety")}
          >
            Safety ({safetyCount})
          </div>
        </div>
        <div className={Hy.rightpart}>
          <select
            className={Hy.dayselect}
            value={interval}
            onChange={(e) => setInterval(e.target.value)}
          >
            <option selected value="day">
              Today
            </option>
            <option value="yesterday">Yesterday</option>
            <option value="week">Last 7 Days</option>
            <option value="month">Last 30 Days</option>
          </select>
        </div>
      </div>
      {/* <div className={Hy.summarycardsholder}>
        <div className={Hy.carouselcard}>
          <div className={Hy.summarytopbar}>
            <BulbIcon className={Hy.bulbicon} />
            <h4 className={Hy.summaryheading}>Quick Summary</h4>
          </div>
          <h5 className={Hy.quicksummaryinfo}>
            Hygiene Index <span className={Hy.improved}>improved</span> since
            Yesterday. <span className={Hy.boldtext}>Sanitaion </span>Score
            <span className={Hy.reduced}> reduced</span> since Yesterday.
          </h5>
        </div>
        <div className={Hy.datainsights}>
          <div className={Hy.summarytopbar}>
            <AnalyticsIcon className={Hy.analyticsicon} />
            <h4 className={Hy.summaryheading}>Data Insights</h4>
          </div>
          <br />
          <h3 className={Hy.quicksummaryinfo}>
            <span className={Hy.boldtext}>Hygiene Index</span> value has
            increased <span className={Hy.greentext}>0.5</span> points ince
            yesterday. And is <span className={Hy.redtext}>0.5</span> lower than
            your weekly average.
          </h3>
          <div className={Hy.buttoninsightsholder}>
            <button className={Hy.insightsbutton}>
              Yesterday: <span className={Hy.orangetext}>3.5</span>
            </button>
            <button className={Hy.insightsbutton}>
              Today: <span className={Hy.greentext}>4</span>
            </button>
          </div>
          <div className={Hy.buttoninsightsholder}>
            <button className={Hy.insightsbutton}>
              Weekly Avg: <span className={Hy.greentext}>4</span>
            </button>
            <button className={Hy.insightsbutton}>
              Montly Avg: <span className={Hy.greentext}>4.5</span>
            </button>
          </div>
        </div>
      </div> */}
      <div className={Hy.cardsholder}>
        {/* Just to check cards */}
        {/* <HairnetDetectionCard />
        <SmokingDetectionCard />
        <GlovesDetectionCard />
        <MaskDetectionCard />
        <HandwashTrackingCard />
        <FloorMoppingCard /> */}
        {hygieneFeatures.length !== 0 ? (
          <React.Fragment>
            {hygieneFeatures.includes("SEMP.SD") ? (
              <SmokingDetectionCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {hygieneFeatures.includes("CS.SA") ? (
              <SanitationCard interval={interval} setInterval={setInterval} />
            ) : null}
            {hygieneFeatures.includes("KPPE.HD") ? (
              <HairnetDetectionCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {hygieneFeatures.includes("KPPE.GD") ? (
              <GlovesDetectionCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {hygieneFeatures.includes("KPPE.FMD") ? (
              <MaskDetectionCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {hygieneFeatures.includes("KHD.HWT") ? (
              <HandwashTrackingCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {hygieneFeatures.includes("KHD.FMM") ? (
              <FloorMoppingCard interval={interval} setInterval={setInterval} />
            ) : null}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/selectcameras")}
            >
              <AddFeatureCard />
            </div>
          </React.Fragment>
        ) : (
          // <div
          //   style={{ cursor: "pointer" }}
          //   onClick={() => navigate("/selectcameras")}
          // >
          //   <AddFeatureCard />
          // </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/selectcameras")}
          >
            <AddFeatureCard />
          </div>
        )}
      </div>
    </div>
  );
};

export default Hygiene;
