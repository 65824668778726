// Dependencies imports
import React from "react";
import { useSelector } from "react-redux";

// Local imports
import ProfileImage from "../../Assets/images/avatar.png";
import { ReactComponent as AddCameraIcon } from "../../Assets/icons/addcameraicon.svg";
import { ReactComponent as AddFeatureIcon } from "../../Assets/icons/addfeatureicon.svg";
import { ReactComponent as EditStoreDetailsIcon } from "../../Assets/icons/editstoredetailsicon.svg";
import { ReactComponent as EditCctvDetailsIcon } from "../../Assets/icons/editcctvdetails.svg";
import { ReactComponent as LogoutIcon } from "../../Assets/icons/logouticon.svg";
import { useNavigate } from "react-router-dom";
import { authData, signOut } from "../../redux/auth/authSlice";
import { useDispatch } from "react-redux";

// Style imports
import Pro from "./profilepopup.module.scss";

const ProfilePopup = (props) => {
  const { setProfileModalShow } = props;
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);
  const navigate = useNavigate();

  function logout() {
    navigate("/");
    dispatch(signOut());
  }

  return (
    <div
      className={Pro.mainmodal}
      onMouseLeave={() => setProfileModalShow(false)}
    >
      <div className={Pro.topbar}>
        <div className={Pro.leftimage}>
          <img
            className={Pro.profileimage}
            src={ProfileImage}
            alt="profileimage"
          />
        </div>
        <div className={Pro.rightimage}>
          <h3 className={Pro.username}>{userDetails.username}</h3>
          <h4 className={Pro.useremail}>{userDetails.email}</h4>
          {/* <div className={Pro.buttonholder}>
            <button className={Pro.editbutton}>Edit</button>
          </div> */}
        </div>
      </div>
      <div className={Pro.middleholder}>
        {/* <div className={Pro.menuholder}>
          <div className={Pro.iconholder}>
            <AddCameraIcon />
          </div>
          <h3 className={Pro.menuheading}>Add/Remove Cameras</h3>
        </div> */}
        <div
          className={Pro.menuholder}
          onClick={() => navigate("/selectcameras")}
        >
          <div className={Pro.iconholder}>
            <AddFeatureIcon />
          </div>
          <h3 className={Pro.menuheading}>Add/Remove Features</h3>
        </div>
        {/* <div className={Pro.menuholder}>
          <div className={Pro.iconholder}>
            <EditStoreDetailsIcon />
          </div>
          <h3 className={Pro.menuheading}>Edit Store Details</h3>
        </div>
        <div className={Pro.menuholder}>
          <div className={Pro.iconholder}>
            <EditCctvDetailsIcon />
          </div>
          <h3 className={Pro.menuheading}>Edit CCTV Details</h3>
        </div> */}
        <div className={Pro.menuholder} onClick={() => logout()}>
          <div className={Pro.iconholder}>
            <LogoutIcon />
          </div>
          <h3 className={Pro.menuheading}>Logout </h3>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;
