// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import InfoIcon from "../../../../../Assets/icons/infoicon.png";
import GenderRatioModal from "./genderratiomodal";
import { ReactComponent as MaleIcon } from "../../../../../Assets/icons/male.svg";
import { ReactComponent as FemaleIcon } from "../../../../../Assets/icons/female.svg";
import { authData } from "../../../../../redux/auth/authSlice";

// Style imports

import Card from "../../hygiene/cardstyle.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const GenderRatioCard = (props) => {
  const { interval, setInterval } = props;
  let userDetails = useSelector(authData);
  const [malePercent, setMalePercent] = useState();
  const [femalePercent, setFemalePercent] = useState(0);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getCardData();
    //eslint-disable-next-line
  }, [interval]);

  const getCardData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/demographics/aggregate/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("gender ratio response", result.data);
        if (
          result.data["result"][0]["male"] !== 0 ||
          result.data["result"][0]["female"] !== 0
        ) {
          let maleNumber = result.data["result"][0]["male"];
          let femaleNumber = result.data["result"][0]["female"];
          let totalNumber =
            result.data["result"][0]["male"] +
            result.data["result"][0]["female"];
          console.log("maleNumber", maleNumber);
          console.log("femaleNumber", femaleNumber);
          let malePercent = (maleNumber / totalNumber) * 100;
          let femalePercent = (femaleNumber / totalNumber) * 100;
          setMalePercent(malePercent.toFixed(2));
          setFemalePercent(femalePercent.toFixed(2));
        } else {
          setMalePercent(0);
          setFemalePercent(0);
        }
      })
      .catch(function (error) {});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={Card.mainbigcontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <GenderRatioModal
            handleClose={handleClose}
            malePercent={malePercent}
            femalePercent={femalePercent}
            interval={interval}
            setInterval={setInterval}
          />
        </Fade>
      </Modal>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Gender Ratio</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      <div className={Card.gendericonholder}>
        <div className={Card.maleiconholder}>
          <MaleIcon className={Card.maleicon} />
          <h4 className={Card.detailicon}>{malePercent}%</h4>
        </div>
        <div className={Card.femaleiconholder}>
          <FemaleIcon className={Card.femaleicon} />
          <h4 className={Card.detailicon}>{femalePercent}%</h4>
        </div>
      </div>
      <button className={Card.learnmore} onClick={() => handleOpen()}>
        Learn More
      </button>
    </div>
  );
};

export default GenderRatioCard;
