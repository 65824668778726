//Dependencies imports
import React from "react";
import Carousel from "./carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Style imports
import Live from "./playback.module.scss";

const PlayBack = () => {
  return (
    <div className={Live.mainholder}>
      <div className={Live.videoholder}>
        <Carousel />
      </div>
    </div>
  );
};

export default PlayBack;
