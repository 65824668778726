// Dependencies imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import reactImageSize from "react-image-size";
import axios from "axios";

// Local imports
import LogoWhite from "../../../Assets/logo/logowhite.png";
import { stateUpdate, authData } from "../../../redux/auth/authSlice";

// Style imports
import Select from "./selectcameras.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

// Start of Select Camera component
const SelectCameras = () => {
  const navigate = useNavigate();
  const [spin, setSpin] = useState(true);
  const [cameraDetails, setCameraDetails] = useState({});
  const [cameraThumbnails, setCameraThumbnails] = useState([]);
  const [cameraNames, setCameraNames] = useState([]);
  const [videoRecorderNumber, setVideoRecordeNumber] = useState();
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getCameraDetails();

    //eslint-disable-next-line
  }, []);

  // API call to get camera details as per outlet code and user email
  const getCameraDetails = () => {
    console.log("userDetails", userDetails);
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/cameras/details/${userDetails.outlet_code.toString()}/${userDetails.email.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setSpin(false);
        console.log(result.data);
        setCameraDetails(result.data);
        setVideoRecordeNumber(result.data.video_recorder_number);
        let temp = [];

        for (const property in result.data.thumbnail_urls) {
          let obj = {};
          obj["cameraname"] = `${property}`;
          obj["thumbnail"] = `${result.data.thumbnail_urls[property]}`;
          temp.push(obj);
        }
        console.log("camerathumb", temp);
        setCameraThumbnails(temp);
        let userData = { ...userDetails };
        userData.cameraDetails = {};
        for (let i = 0; i < temp.length; i++) {
          userData.cameraDetails[temp[i].cameraname] = temp[i].thumbnail;
        }
        dispatch(stateUpdate(userData));
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        setSpin(false);
      });
  };

  // Function to get dimensions of the selected camera thumbnail for roi
  async function getImageDimension(value, i) {
    try {
      const { width, height } = await reactImageSize(value.thumbnail);
      console.log("cam div click", value);
      let userData = { ...userDetails };
      userData.camera_number = value.cameraname;
      userData.roi_image = value.thumbnail;
      userData.roi_image_height = 480;
      userData.roi_image_width = 640;
      userData.video_recorder_number = videoRecorderNumber;
      dispatch(stateUpdate(userData));
      navigate("/selectfeatures");
    } catch (error) {
      console.log(error);
    }
  }

  const giveCameraNames = (props) => {
    if (props === "cam_1") {
      return "Camera 1";
    } else if (props === "cam_2") {
      return "Camera 2";
    } else if (props === "cam_3") {
      return "Camera 3";
    } else if (props === "cam_4") {
      return "Camera 4";
    } else if (props === "cam_5") {
      return "Camera 5";
    } else if (props === "cam_6") {
      return "Camera 6";
    } else if (props === "cam_7") {
      return "Camera 7";
    } else if (props === "cam_8") {
      return "Camera 8";
    } else if (props === "cam_9") {
      return "Camera 9";
    } else if (props === "cam_10") {
      return "Camera 10";
    } else if (props === "cam_11") {
      return "Camera 11";
    } else if (props === "cam_12") {
      return "Camera 12";
    } else if (props === "cam_13") {
      return "Camera 13";
    } else if (props === "cam_14") {
      return "Camera 14";
    } else if (props === "cam_15") {
      return "Camera 15";
    } else if (props === "cam_16") {
      return "Camera 16";
    } else if (props === "cam_17") {
      return "Camera 17";
    } else if (props === "cam_18") {
      return "Camera 18";
    } else if (props === "cam_19") {
      return "Camera 19";
    } else if (props === "cam_20") {
      return "Camera 20";
    } else if (props === "cam_21") {
      return "Camera 21";
    }
  };

  return (
    <div className={Select.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Select.header}>
        <div className={Select.leftheader}>
          <img src={LogoWhite} alt="oureyelogo" className={Select.logoimage} />
        </div>
        <div className={Select.rightheader}>
          <h3 className={Select.username}>Hello {userDetails.username},</h3>
        </div>
      </div>
      <div className={Select.belowbody}>
        <h3 className={Select.heading}>Select Your Camera</h3>
        <div className={Select.cameracardsholder}>
          {cameraThumbnails.map((value, i) => {
            return (
              <div
                className={Select.cameracard}
                onClick={() => {
                  getImageDimension(value, i);
                }}
                key={i}
              >
                {spin === true ? (
                  <Skeleton variant="rect" className={Select.image} />
                ) : (
                  <img
                    className={Select.image}
                    src={value.thumbnail}
                    alt="cameraimage"
                  />
                )}
                <div className={Select.cameradetails}>
                  <div className={Select.leftside}>
                    <h3 className={Select.cameraname}>
                      {giveCameraNames(value.cameraname)}
                    </h3>
                    <h4 className={Select.camerabrand}>
                      {cameraDetails.cctv_brand}
                    </h4>
                  </div>
                  <div className={Select.rightside}>
                    <div className={Select.dot}></div>
                    <h5 className={Select.camerastatus}>Active</h5>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectCameras;
