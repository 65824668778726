// Dependencies imports
import React from "react";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Local imports
import InfoIcon from "../../../../Assets/icons/infoicon.png";
import UnderTraining from "../../../../Assets/icons/undertraining.gif";

// Style imports
import Card from "./cardstyle.module.scss";

const MaskDetectionCard = () => {
  // const percentage = 69;
  return (
    <div className={Card.mainbigcontainer}>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Mask Detection</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      {/* <div className={Card.middleholder}>
        <div className={Card.leftside}>
          <h4 className={Card.featurevalue}>Undetected:</h4>
          <h5 className={Card.featuredatablue}>10</h5>
        </div>
        <div className={Card.rightside}>
          <h4 className={Card.featurevalue}>Compliance Score:</h4>
          <h5 className={Card.featuredatayellow}>68%</h5>
        </div>
      </div>
      <button className={Card.learnmore}>Learn More</button> */}
      <div className={Card.underprogressholder}>
        <img
          src={UnderTraining}
          className={Card.undertraininggif}
          alt="undertraininggif"
        />
        <h4 className={Card.underprogress}>Training under progress</h4>
      </div>
    </div>
  );
};

export default MaskDetectionCard;
