// Dependencies imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

// Local imports
import Logo from "../../../Assets/logo/logo.png";
import { ReactComponent as LoginImage } from "../../../Assets/images/signin.svg";
import EmailIcon from "../../../Assets/images/emailicon.png";
import PasswordIcon from "../../../Assets/images/passwordicon.png";
import { stateUpdate, authData } from "../../../redux/auth/authSlice";

// Style imports
import Sign from "./signup.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

// Start of main component
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [spin, setSpin] = useState(false);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const classes = useStyles();

  const saveTokenAndNavigate = (token) => {
    swal({
      title: "Success!",
      text: "Logged in",
      icon: "success",
      buttons: false,
    });
    let userData = { ...userDetails };
    userData.authtoken = token;
    let alldata = jwt_decode(token);
    userData.username = alldata.name;
    userData.email = alldata.email;
    userData.company_code = alldata.company_code;
    userData.onboarding_state = parseInt(alldata.onboarding_state);
    userData.outlet_code = alldata.outlets_access[0];
    dispatch(stateUpdate(userData));
    setSpin(false);
    console.log("decodeddata", alldata);
    console.log("onboarding state", parseInt(alldata.onboarding_state));

    if (parseInt(alldata.onboarding_state) === 1) {
      navigate("/outletdetails");
    } else if (parseInt(alldata.onboarding_state) === 2) {
      navigate("/continueonphone");
    } else if (
      parseInt(alldata.onboarding_state) === 3 ||
      parseInt(alldata.onboarding_state) === 4
    ) {
      navigate("/selectcameras");
    } else if (parseInt(alldata.onboarding_state) === 5) {
      navigate("/hygiene");
    }
  };

  // Function to validate login form
  const validateForm = () => {
    let formIsValid = true;
    setEmailErr("");
    setPasswordErr("");

    if (email === "" || email === null || email.trim() === "") {
      formIsValid = false;
      setEmailErr("Please enter your email");
      return;
    }
    if (password === "" || password === null || password.trim() === "") {
      formIsValid = false;
      setPasswordErr("Please enter your password");
      return;
    }
    if (formIsValid === true) {
      console.log("form is valid");
      loginUser();

      return;
    }
  };

  //API to send user details
  const loginUser = () => {
    setSpin(true);
    var data = qs.stringify({
      email: email.toString(),
      password: password.toString(),
    });

    var config = {
      method: "POST",
      url: "https://api.oc3.oureye.ai/auth/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log(result.data["access_token"]);
        saveTokenAndNavigate(result.data["access_token"]);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Incorrect Username or Password",
          icon: "error",
          buttons: false,
        });
      });
  };

  return (
    <div className={Sign.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      {/* Left Side of the screen */}
      <div className={Sign.leftside}>
        <img src={Logo} alt="oureyelogo" className={Sign.logoimage} />
        <div className={Sign.holder}>
          <LoginImage className={Sign.signupicon} />
        </div>
      </div>

      {/* Right side of the screen */}
      <div className={Sign.rightside}>
        <h3 className={Sign.heading}>Login</h3>
        <div className={Sign.formholder}>
          <div className={Sign.inputholder}>
            <img
              src={EmailIcon}
              alt="outletnamecon"
              className={Sign.fieldimage}
            />
            <input
              type="text"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  validateForm();
                }
              }}
              placeholder="Email"
              className={Sign.inputfield}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <h4 className={Sign.errormessage}>{emailErr}</h4>
          <div className={Sign.inputholder}>
            <img
              src={PasswordIcon}
              alt="outletlocationicon"
              className={Sign.fieldimage}
            />
            <input
              type="password"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  validateForm();
                }
              }}
              placeholder="Password"
              className={Sign.inputfield}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <h4 className={Sign.errormessage}>{passwordErr}</h4>
          <button className={Sign.button} onClick={() => validateForm()}>
            Login
          </button>
        </div>
        <div className={Sign.nouserholder}>
          <h4 className={Sign.nouser}>
            Not an user?{" "}
            <span
              className={Sign.signuplink}
              onClick={() => navigate("/signup")}
            >
              Signup
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Login;
