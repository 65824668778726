// Dependencies imports
import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";

// Local imports
import LogoWhite from "../../../Assets/logo/logowhite.png";
import DownloadIcon from "../../../Assets/images/downloadicon.png";
import { ReactComponent as OnboardingIcon } from "../../../Assets/images/firstonboarding.svg";
import { ReactComponent as RefreshIcon } from "../../../Assets/icons/refresh.svg";
import DownloadApp from "./downloadappmodal";
import { stateUpdate, authData } from "../../../redux/auth/authSlice";

// Style import
import Continue from "./continueonphone.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

// Start of continue on phone component
const ContinueOnPhone = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  useEffect(() => {
    handleOpen();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  window.onbeforeunload = () => getOutletAccess();

  const getOutletAccess = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/users/outlet_access/${userDetails.email.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("outlet access", result.data.result[0]["outlet_code"]);
        let userData = { ...userDetails };
        userData.outlet_code = result.data.result[0]["outlet_code"];
        dispatch(stateUpdate(userData));
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        swal({
          title: "Oops!",
          text: "Something went wrong",
          icon: "error",
          buttons: false,
        });
      });
  };

  return (
    <div className={Continue.maincontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <DownloadApp handleClose={handleClose} />
        </Fade>
      </Modal>
      <div className={Continue.header}>
        <div className={Continue.leftheader}>
          <img
            src={LogoWhite}
            alt="oureyelogo"
            className={Continue.logoimage}
          />
        </div>
        <div className={Continue.rightheader}>
          <img
            src={DownloadIcon}
            alt="downloadicon"
            className={Continue.downloadicon}
            onClick={() => handleOpen()}
          />

          <button
            className={Continue.downloadbutton}
            onClick={() => getOutletAccess()}
          >
            <RefreshIcon className={Continue.refreshicon} />
            Refresh Page
          </button>
        </div>
      </div>
      <div className={Continue.belowbody}>
        <OnboardingIcon className={Continue.onboardingicon} />
        <h3 className={Continue.headingbelow}>Continue on App</h3>
        <p className={Continue.phrase}>
          Seems like you have not finished your onboarding process on phone.
          Please finish the further steps <br />
          by downloading app.
        </p>
        {/* <h4 className={Continue.steptext}>Step 1/3</h4>
        <div className={Continue.progressholder}>
          <div className={Continue.first}></div>
          <div className={Continue.second}></div>
          <div className={Continue.third}></div>
        </div> */}
      </div>
    </div>
  );
};

export default ContinueOnPhone;
