// Dependencies imports
import React from "react";

// Local imports
import InfoIcon from "../../../../Assets/icons/infoicon.png";
import { ReactComponent as StoreTimeIcon } from "../../../../Assets/icons/storetimecard.svg";
import UnderTraining from "../../../../Assets/icons/undertraining.gif";

// Style imports
import Card from "../hygiene/cardstyle.module.scss";

const StoreTimingsCard = () => {
  return (
    <div className={Card.mainbigcontainer}>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Store Timings</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      {/* <div className={Card.middleholder}>
        <div className={Card.leftbigholder}>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>Open Time:</h5>
            <h3 className={Card.datavalueblue}>06:43 AM</h3>
          </div>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>
              Close Time: <h3 className={Card.datavalueblue}>08:50 PM</h3>
            </h5>
          </div>
          <br />

          <button className={Card.learnmore}>Learn More</button>
        </div>
        <div className={Card.rightholder}>
          <div className={Card.iconholder}>
            <StoreTimeIcon className={Card.icon} />
          </div>
        </div>
      </div> */}
      <div className={Card.underprogressholder}>
        <img
          src={UnderTraining}
          className={Card.undertraininggif}
          alt="undertraininggif"
        />
        <h4 className={Card.underprogress}>Training under progress</h4>
      </div>
    </div>
  );
};

export default StoreTimingsCard;
