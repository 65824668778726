// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import InfoIcon from "../../../../../Assets/icons/infoicon.png";
import { authData } from "../../../../../redux/auth/authSlice";
import RoomOccupancyModal from "./roomoccupancymodal";

// Style imports
import Card from "../../hygiene/cardstyle.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const RoomOccupancyCard = (props) => {
  const { interval, setInterval } = props;
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [average, setAverage] = useState();
  const [playAll, setPlayAll] = useState([]);

  useEffect(() => {
    getCardData();
    //eslint-disable-next-line
  }, [interval]);

  const getCardData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/room_occupancy/aggregate/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("room occupancy response", result.data);
        setAverage(result.data["result"][0]["average_count"]);
        if (userDetails.email === "playallfaridabad@oureye.ai") {
          getAverage();
        }
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
      });
  };

  const getAverage = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/room_occupancy/aggregate_area/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("room occupancy arearesponse", result.data);
        setPlayAll(result.data["result"]);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={Card.mainbigcontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <RoomOccupancyModal
            handleClose={handleClose}
            average={average}
            playAll={playAll}
            interval={interval}
            setInterval={setInterval}
          />
        </Fade>
      </Modal>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Room Occupancy</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      <div className={Card.middleholder}>
        <div className={Card.leftbigholder}>
          <div className={Card.eventsholderplay}>
            {userDetails.email === "playallfaridabad@oureye.ai" ? (
              playAll.slice(0, 2).map((value, i) => {
                return (
                  <div className={Card.eventsholder} key={i}>
                    <h5 className={Card.featuredetails}>{value.roi_name}: </h5>
                    <div className={Card.datavalueblue}>{value.count}</div>
                  </div>
                );
              })
            ) : (
              <div className={Card.eventsholder}>
                <h5 className={Card.featuredetails}>Average: </h5>
                <div className={Card.datavalueblue}>{average}</div>
              </div>
            )}
          </div>
          <br />
          <button className={Card.learnmore} onClick={() => handleOpen()}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoomOccupancyCard;
