// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import qs from "qs";

// Local imports
import { ReactComponent as AddCircle } from "../../../Assets/icons/addcircle.svg";
import { stateUpdate, authData } from "../../../redux/auth/authSlice";

// Style imports
import Add from "./addingschedule.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const AddingSchedule = (props) => {
  const { handleCloseAddSchedule, handleOpenSchedule, handleOpenSyncDemo } =
    props;
  const [spin, setSpin] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [selected, setSelected] = useState();
  const [saveButton, setSaveButton] = useState(false);
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getSchedulingTemplates();
    //eslint-disable-next-line
  }, []);

  // API call to get scheduled templates
  const getSchedulingTemplates = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/onboard/feature_schedule_template/${userDetails.company_code.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setSpin(false);
        setTemplates(result.data);
        console.log("result of templates", result.data);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        setSpin(false);
      });
  };

  // Function to onboard features
  const startOnboardingFeatures = () => {
    setSpin(true);
    console.log("video recorder number");
    let featureObj = {};
    let featureArray = [];
    featureArray.push(userDetails.feature_code);
    featureObj[userDetails.camera_number] = featureArray;
    console.log("featureObject", featureObj);
    console.log("userDetails", userDetails);
    var data = qs.stringify({
      outlet_code: userDetails.outlet_code,
      video_recorder_number: userDetails.video_recorder_number,
      camera_features: JSON.stringify(featureObj),
    });

    var config = {
      method: "POST",
      url: "https://api.oc3.oureye.ai/onboard/camera_features",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log(result);
        setSpin(false);
        sendFeatureSchedule();
      })
      .catch(function (error) {
        setSpin(false);
        console.log("error first", error.response.status);
        if (error.response.status === 400) {
          console.log(error);
        }
      });
  };

  // Function to send feature schedule
  const sendFeatureSchedule = () => {
    setSpin(true);
    let obj = {};
    obj["repeating_days"] = userDetails.repeating_days;

    let roisobj = {};
    roisobj["rois"] = userDetails.roi_coordinates;

    var data = qs.stringify({
      outlet_code: userDetails.outlet_code,
      video_recorder_number: userDetails.video_recorder_number,
      camera_number: userDetails.camera_number,
      feature_code: userDetails.feature_code,
      start_time: userDetails.start_time,
      end_time: userDetails.end_time,
      repeating_days: JSON.stringify(obj),
      onboarded_by: userDetails.email,
      rois: JSON.stringify(roisobj),
      roi_name: userDetails.roiname,
    });

    var config = {
      method: "POST",
      url: "https://api.oc3.oureye.ai/onboard/feature_schedule",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log(result);
        setSpin(false);
        let userData = { ...userDetails };
        delete userData.roi_coordinates;
        delete userData.area_selected;
        delete userData.start_time;
        delete userData.end_time;
        delete userData.repeating_days;
        dispatch(stateUpdate(userData));
        handleCloseAddSchedule();
        handleOpenSyncDemo();
      })
      .catch(function (error) {
        setSpin(false);
        console.log("error first", error.response.status);
        if (error.response.status === 400) {
          console.log(error);
        }
      });
  };

  return (
    <div className={Add.mainpopup}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <h3 className={Add.heading}>Features Schedule:</h3>
      <div className={Add.templatesholder}>
        <div
          className={Add.createnewdiv}
          onClick={() => {
            handleCloseAddSchedule();
            handleOpenSchedule();
          }}
        >
          <AddCircle className={Add.addcircle} />
          <h4 className={Add.createnewhead}>Create New</h4>
        </div>
        {templates.map((value, i) => {
          return (
            <div className={Add.template}>
              <div className={Add.topbar}>
                <h3 className={Add.templatename}>
                  {value.schedule_name.length > 10
                    ? value.schedule_name.substring(
                        0,
                        value.schedule_name.length -
                          (value.schedule_name.length - 10)
                      )
                    : value.schedule_name}
                </h3>
                <input
                  type="radio"
                  className={Add.radiobutton}
                  checked={selected === i ? true : false}
                  name={value.schedule_name}
                  onChange={() => {
                    setSelected(i);
                    let userData = { ...userDetails };
                    userData.start_time = value.start_time;
                    userData.end_time = value.end_time;
                    userData.repeating_days = value.repeating_days;
                    dispatch(stateUpdate(userData));
                    setSaveButton(true);
                  }}
                />
              </div>
              <div className={Add.dataholder}>
                <h4 className={Add.datalabel}>Time:</h4>
                <h5 className={Add.datavalue}>
                  {value.start_time} - {value.end_time}
                </h5>
              </div>
              <div className={Add.daysholder}>
                <h4 className={Add.datalabel}>Days:</h4>
                <div className={Add.days}>
                  <button
                    className={
                      value.repeating_days.includes(6)
                        ? Add.weekbuttonactive
                        : Add.weekbutton
                    }
                  >
                    S
                  </button>
                  <button
                    className={
                      value.repeating_days.includes(0)
                        ? Add.weekbuttonactive
                        : Add.weekbutton
                    }
                  >
                    M
                  </button>
                  <button
                    className={
                      value.repeating_days.includes(1)
                        ? Add.weekbuttonactive
                        : Add.weekbutton
                    }
                  >
                    T
                  </button>
                  <button
                    className={
                      value.repeating_days.includes(2)
                        ? Add.weekbuttonactive
                        : Add.weekbutton
                    }
                  >
                    W
                  </button>
                  <button
                    className={
                      value.repeating_days.includes(3)
                        ? Add.weekbuttonactive
                        : Add.weekbutton
                    }
                  >
                    T
                  </button>
                  <button
                    className={
                      value.repeating_days.includes(4)
                        ? Add.weekbuttonactive
                        : Add.weekbutton
                    }
                  >
                    F
                  </button>
                  <button
                    className={
                      value.repeating_days.includes(5)
                        ? Add.weekbuttonactive
                        : Add.weekbutton
                    }
                  >
                    S
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={Add.buttonholder}>
        {saveButton === false ? (
          <button className={Add.buttondisabled}>Save and Continue</button>
        ) : (
          <button
            className={Add.button}
            onClick={() => {
              startOnboardingFeatures();
            }}
          >
            Save and Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default AddingSchedule;
