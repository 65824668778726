// Dependencies imports
import React from "react";

// Local imports
import InfoIcon from "../../../../Assets/icons/infoicon.png";
import NoImage from "../../../../Assets/images/noimage.png";
// import OpenIcon from "../../../../Assets/images/openicon.png";
import UnderTraining from "../../../../Assets/icons/undertraining.gif";

// Style imports

import Card from "../hygiene/cardstyle.module.scss";

const ViolenceDetectionCard = () => {
  return (
    <div className={Card.mainbigcontainer}>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Violence Detection</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      {/* <div className={Card.middleholder}>
        <div className={Card.leftimageholder}>
          <img className={Card.featureimage} src={NoImage} alt="featureimage" />
        </div>
        <div className={Card.rightcardholder}>
          <div className={Card.eventsholder}>
            <h4 className={Card.featuredetails}>Count: </h4>
            <h5 className={Card.datavaluered}>--</h5>
          </div>
          <div className={Card.lasteventholder}>
            <h4 className={Card.featuredetails}>Last Event: </h4>
            <h5 className={Card.datavalueblue}>--:-- --</h5>
          </div>
        </div>
      </div> */}
      <div className={Card.underprogressholder}>
        <img
          src={UnderTraining}
          className={Card.undertraininggif}
          alt="undertraininggif"
        />
        <h4 className={Card.underprogress}>Training under progress</h4>
      </div>
    </div>
  );
};

export default ViolenceDetectionCard;
