// Dependencies imports
import React from "react";

// Local imports
import InfoIcon from "../../../../Assets/icons/infoicon.png";
import StoreHeatMap from "../../../../Assets/images/storeheatmap.png";
import OpenIcon from "../../../../Assets/images/openicon.png";
import UnderTraining from "../../../../Assets/icons/undertraining.gif";

// Style imports
import Card from "../hygiene/cardstyle.module.scss";

const StoreHeadMapCard = () => {
  return (
    <div className={Card.mainbigcontainer}>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Store Heatmaps</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      {/* <div className={Card.middleholder}>
        <div className={Card.leftimageholder}>
          <img
            className={Card.featureimage}
            src={StoreHeatMap}
            alt="featureimage"
          />
          <img className={Card.openicon} src={OpenIcon} alt="openicon" />
        </div>
        <div className={Card.rightcardholder}>
          <h4 className={Card.featuredetails}>Highest Foot Fall: </h4>
          <h5 className={Card.datavalueblue}>59</h5>
        </div>
      </div> */}
      <div className={Card.underprogressholder}>
        <img
          src={UnderTraining}
          className={Card.undertraininggif}
          alt="undertraininggif"
        />
        <h4 className={Card.underprogress}>Training under progress</h4>
      </div>
    </div>
  );
};

export default StoreHeadMapCard;
