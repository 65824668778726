// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import InfoIcon from "../../../../../Assets/icons/infoicon.png";
import NoImage from "../../../../../Assets/images/noimage.png";
import OpenIcon from "../../../../../Assets/images/openicon.png";
import { authData } from "../../../../../redux/auth/authSlice";
import IntrusionDetectionModal from "./intrusiondetectionmodal";

// Style imports
import Card from "../../hygiene/cardstyle.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const IntrusionDetectionCard = (props) => {
  const { interval, setInterval } = props;
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState();
  const [screenshot, setScreenShot] = useState();
  const [lastevent, setLastEvent] = useState();

  useEffect(() => {
    getCardData();
    //eslint-disable-next-line
  }, [interval]);

  const getCardData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/intrusion_detection/aggregate/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setCount(result.data["result"][0]["intrusion_count"]);
        setScreenShot(result.data["result"][0]["screenshot"]);
        setLastEvent(result.data["result"][0]["last_not_intrusion"]);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={Card.mainbigcontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <IntrusionDetectionModal
            handleClose={handleClose}
            count={count}
            lastevent={lastevent}
            interval={interval}
            setInterval={setInterval}
          />
        </Fade>
      </Modal>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Intrusion Detection</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      <div className={Card.middleholder}>
        <div className={Card.leftimageholder}>
          <img
            className={Card.featureimage}
            src={
              screenshot !== "" && screenshot !== null ? screenshot : NoImage
            }
            alt="featureimage"
          />
          <img
            className={Card.openicon}
            src={OpenIcon}
            alt="openicon"
            onClick={() => handleOpen()}
          />
        </div>
        <div className={Card.rightcardholder}>
          <div className={Card.eventsholder}>
            <h4 className={Card.featuredetails}>Count: </h4>
            <h5 className={Card.datavaluered}>{count !== null ? count : 0}</h5>
          </div>
          <div className={Card.lasteventholder}>
            <h4 className={Card.featuredetails}>Last Event: </h4>
            <h5 className={Card.datavalueblue}>
              {lastevent !== null ? moment(lastevent).format("LT") : "--"}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntrusionDetectionCard;
