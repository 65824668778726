import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const BarChart = (props) => {
  const { labels, dataset } = props;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Hourly Average",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Counts",
        data: dataset,
        backgroundColor: [
          "#7ED957",
          "#00B980",
          "#009299",
          "#006995",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
      },
    ],
  };

  return (
    <div
      style={{
        width: "40%",
        height: "40%",
        position: "absolute",
        top: "40%",
        left: "45%",
      }}
    >
      <Pie data={data} options={options} />
    </div>
  );
};

export default BarChart;
