// Dependencies imports
import React from "react";

// Local imports
import { ReactComponent as Close } from "../../../Assets/icons/cancel.svg";

// Style imports
import Fea from "./featuredescription.module.scss";

// Start of Feature description component
const FeatureDescription = (props) => {
  const {
    handleCloseFeatureDescription,
    handleOpenRoi,
    featureName,
    featureDescription,
    featureImage,
  } = props;

  return (
    <div className={Fea.mainpopup}>
      <div className={Fea.iconholder}>
        <Close
          className={Fea.closeicon}
          onClick={handleCloseFeatureDescription}
        />
      </div>
      <h3 className={Fea.heading}>{featureName}:</h3>
      <h4 className={Fea.featuredescription}>{featureDescription}</h4>
      <img className={Fea.featureimage} src={featureImage} alt="featureimage" />
      <div className={Fea.buttonholder}>
        <button
          className={Fea.button}
          onClick={() => {
            handleCloseFeatureDescription();
            handleOpenRoi();
          }}
        >
          Activate
        </button>
      </div>
    </div>
  );
};

export default FeatureDescription;
