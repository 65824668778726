// Dependencies imports
import React from "react";

// Local imports
import InfoIcon from "../../../../Assets/icons/infoicon.png";
import { ReactComponent as SecurityTeamPresenceIcon } from "../../../../Assets/icons/securityteampresence.svg";
import UnderTraining from "../../../../Assets/icons/undertraining.gif";

// Style imports
import Card from "../hygiene/cardstyle.module.scss";

const SecurityTeamPresenceCard = () => {
  return (
    <div className={Card.mainbigcontainer}>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Security Team Presence</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      {/* <div className={Card.middleholder}>
        <div className={Card.leftbigholder}>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>Count:</h5>
            <h5 className={Card.datavaluered}>7</h5>
          </div>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>Last Event:</h5>
            <h5 className={Card.datavalueblue}>08:50 PM</h5>
          </div>
          <br />
          <button className={Card.learnmore}>Learn More</button>
        </div>
        <div className={Card.rightholder}>
          <div className={Card.iconholder}>
            <SecurityTeamPresenceIcon className={Card.icon} />
          </div>
        </div>
      </div> */}
      <div className={Card.underprogressholder}>
        <img
          src={UnderTraining}
          className={Card.undertraininggif}
          alt="undertraininggif"
        />
        <h4 className={Card.underprogress}>Training under progress</h4>
      </div>
    </div>
  );
};

export default SecurityTeamPresenceCard;
