// Dependencies imports
import React, { useMemo, useRef, useState, useEffect } from "react";
import { Stage, Layer, Image } from "react-konva";
import { useSelector, useDispatch } from "react-redux";

// Local imports
import PolygonAnnotation from "./PolygonAnnotation";
import Button from "./Button";
import { stateUpdate, authData } from "../../../redux/auth/authSlice";

const wrapperStyle = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "white",
};
const columnStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
};
const Canvas = () => {
  const [image, setImage] = useState();
  const imageRef = useRef(null);
  const [points, setPoints] = useState([]);
  const [size, setSize] = useState({});
  const [flattenedPoints, setFlattenedPoints] = useState();
  const [position, setPosition] = useState([0, 0]);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [isPolyComplete, setPolyComplete] = useState(false);
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const videoSource = userDetails.roi_image;

  const videoElement = useMemo(() => {
    const element = new window.Image();
    element.width = userDetails.roi_image_width;
    element.height = userDetails.roi_image_height;
    element.src = videoSource;
    return element;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoSource]); //it may come from redux so it may be dependency that's why I left it as dependecny...

  useEffect(() => {
    const onload = function () {
      setSize({
        width: userDetails.roi_image_width,
        height: userDetails.roi_image_height,
      });
      setImage(videoElement);
      imageRef.current = videoElement;
    };
    videoElement.addEventListener("load", onload);
    return () => {
      videoElement.removeEventListener("load", onload);
    };
    //eslint-disable-next-line
  }, [videoElement]);

  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };

  //drawing begins when mousedown event fires.
  const handleMouseDown = (e) => {
    if (isPolyComplete) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isMouseOverPoint && points.length >= 3) {
      setPolyComplete(true);
    } else {
      setPoints([...points, mousePos]);
    }
  };

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    setPosition(mousePos);
  };

  const handleMouseOverStartPoint = (e) => {
    if (isPolyComplete || points.length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
    let userData = { ...userDetails };
    userData.roi_coordinates = points;
    userData.area_selected = true;
    dispatch(stateUpdate(userData));
    console.log("area true");
  };

  const handleMouseOutStartPoint = (e) => {
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };

  const handlePointDragMove = (e) => {
    const stage = e.target.getStage();
    const index = e.target.index - 1;
    const pos = [e.target._lastPos.x, e.target._lastPos.y];
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;
    if (pos[0] > stage.width()) pos[0] = stage.width();
    if (pos[1] > stage.height()) pos[1] = stage.height();
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
    console.log("handlePointDragMove", points);
  };

  useEffect(() => {
    setFlattenedPoints(
      points
        .concat(isPolyComplete ? [] : position)
        .reduce((a, b) => a.concat(b), [])
    );
  }, [points, isPolyComplete, position]);

  const undo = () => {
    setPoints(points.slice(0, -1));

    setPolyComplete(false);
    setPosition(points[points.length - 1]);
    let userData = { ...userDetails };
    userData.area_selected = false;
    dispatch(stateUpdate(userData));
  };

  const reset = () => {
    console.log(points);
    setPoints([]);
    setPolyComplete(false);
    let userData = { ...userDetails };
    userData.area_selected = false;
    dispatch(stateUpdate(userData));
  };

  const handleGroupDragEnd = (e) => {
    //drag end listens other children circles' drag end event
    //...that's, why 'name' attr is added, see in polygon annotation part
    if (e.target.name() === "polygon") {
      let result = [];
      let copyPoints = [...points];
      copyPoints.map((point) =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      );
      e.target.position({ x: 0, y: 0 }); //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
      setPoints(result);
      console.log("handleGroupDragEnd", points);
    }
  };

  return (
    <div style={wrapperStyle}>
      <div style={columnStyle}>
        <Stage
          width={size.width || 650}
          height={size.height || 302}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
        >
          <Layer>
            <Image
              ref={imageRef}
              image={image}
              x={0}
              y={0}
              width={size.width}
              height={size.height}
            />
            <PolygonAnnotation
              points={points}
              flattenedPoints={flattenedPoints}
              handlePointDragMove={handlePointDragMove}
              handleGroupDragEnd={handleGroupDragEnd}
              handleMouseOverStartPoint={handleMouseOverStartPoint}
              handleMouseOutStartPoint={handleMouseOutStartPoint}
              isFinished={isPolyComplete}
            />
          </Layer>
        </Stage>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 10px",
            gap: "20px",
          }}
        >
          <Button name="Undo" onClick={undo} />
          <Button name="Reset" onClick={reset} />
        </div>
      </div>
    </div>
  );
};

export default Canvas;
