// Dependencies imports
import React from "react";

// Local imports
import SideNav from "../sidenav/sidenav";
import Header from "../headers/header";
import PlayBack from "../Components/Screens/playbackscreen/playback";

// Style imports
import Lay from "./layout.module.scss";

const PlaybackLayout = () => {
  return (
    <div className={Lay.maincontainer}>
      <Header />
      <div className={Lay.nextcontainer}>
        <SideNav />
        <PlayBack />
      </div>
    </div>
  );
};

export default PlaybackLayout;
