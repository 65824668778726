import React from "react";

import Mo from "../billingcounterunmanned/graphstyles.module.scss";
import { ReactComponent as Close } from "../../../../../Assets/images/close.svg";

const ImageViewModal = (props) => {
  const { modalImage, handleModalClose } = props;
  return (
    <div className={Mo.modalholder}>
      <div className={Mo.modaltopbar}>
        <Close className={Mo.closeicon} onClick={() => handleModalClose()} />
      </div>
      <img className={Mo.image} alt="eventimage" src={modalImage} />
    </div>
  );
};

export default ImageViewModal;
