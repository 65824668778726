// Dependencies import
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Local imports
import Canvas from "./Canvas";
import { stateUpdate, authData } from "../../../redux/auth/authSlice";

// Style imports
import Roi from "./roimaker.module.scss";

const RoiMaker = (props) => {
  const { handleCloseRoi, handleOpenAddSchedule } = props;
  const [roiname, setRoiName] = useState("");
  const [roinameError, setRoiNameError] = useState("");
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  // Function to validate login form
  const validateRoi = () => {
    let formIsValid = true;
    setRoiNameError("");

    if (roiname === "" || roiname === null || roiname.trim() === "") {
      formIsValid = false;
      setRoiNameError("Area name cannot be empty");
      return;
    }
    if (formIsValid === true) {
      let userData = { ...userDetails };
      userData.roiname = roiname;
      dispatch(stateUpdate(userData));
      handleCloseRoi();
      handleOpenAddSchedule();
      return;
    }
  };

  return (
    <div className={Roi.mainmodal}>
      <h3 className={Roi.heading}>Draw the ROI on the frame shown below:</h3>
      <Canvas />
      <div className={Roi.roinameholder}>
        <input
          type="text"
          placeholder="Enter Area Name"
          value={roiname}
          className={Roi.inputfield}
          onChange={(e) => setRoiName(e.target.value)}
        />
        <h4 className={Roi.errormessage}>{roinameError}</h4>
      </div>
      <div className={Roi.buttonholder}>
        {userDetails.area_selected === true ? (
          <button
            className={Roi.continuebutton}
            onClick={() => {
              console.log("roicoordinates", userDetails.roi_coordinates);
              validateRoi();
            }}
          >
            Save and Continue
          </button>
        ) : (
          <button className={Roi.continuebuttondisabled}>
            Save and Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default RoiMaker;
