// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import InfoIcon from "../../../../../Assets/icons/infoicon.png";
import { ReactComponent as PeopleCounterIcon } from "../../../../../Assets/icons/peoplecountercard.svg";
import { authData } from "../../../../../redux/auth/authSlice";
import FootFallModal from "./footfallmodal";

// Style imports
import Card from "../../hygiene/cardstyle.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const FootFall = (props) => {
  const { interval, setInterval } = props;
  let userDetails = useSelector(authData);
  const [count, setCount] = useState(0);
  const [rushHour, setRushHour] = useState();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getCardData();
    //eslint-disable-next-line
  }, [interval]);

  const getCardData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/people_count/aggregate/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setCount(result.data["total_people_count"]);
        setRushHour(result.data["rush_hour"]);
        getGraphData();
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
      });
  };

  function getMaxValueKey(data) {
    const maxValue = Object.entries(data).sort((x, y) =>
      x[1].length !== 0 && y[1].length !== 0
        ? y[1][0]["people_count"] - x[1][0]["people_count"]
        : null
    )[0];
    console.log(
      "x entries",
      Object.entries(data).sort((x, y) =>
        x[1].length !== 0
          ? console.log("x entries", x[1][0]["people_count"])
          : console.log("undefined")
      )
    );
    console.log("max value", maxValue);
  }

  //   API to get graph and logs response
  const getGraphData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/people_count/aggregate_list/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        getMaxValueKey(result.data["result"]);
      })
      .catch(function (error) {});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={Card.mainbigcontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <FootFallModal
            handleClose={handleClose}
            count={count}
            rushHour={rushHour}
            interval={interval}
            setInterval={setInterval}
          />
        </Fade>
      </Modal>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Foot Fall</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      <div className={Card.middleholder}>
        <div className={Card.leftbigholder}>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>Count:</h5>
            <h5 className={Card.datavalueblue}>{count}</h5>
          </div>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>Rush Hour:</h5>
            <h5 className={Card.datavalueblue}>
              {rushHour !== null ? rushHour : "00"}
              {":00"} - {rushHour !== null ? rushHour + 1 : "00"}
              {":00"}
            </h5>
          </div>
          <br />
          <button className={Card.learnmore} onClick={() => handleOpen()}>
            Learn More
          </button>
        </div>
        <div className={Card.rightholder}>
          <div className={Card.iconholder}>
            <PeopleCounterIcon className={Card.icon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FootFall;
