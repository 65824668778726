// Dependencies imports
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

// Local imports
import Close from "../../../../../Assets/icons/blueicon.png";
import BarChart from "./customertimeinsightgraph";
import { authData } from "../../../../../redux/auth/authSlice";
import ImagePlaceholder from "../../../../../Assets/images/imageplaceholder.png";
import ImageViewModal from "../roomoccupancy/imageviewmodal";

// Style imports
import Graph from "../billingcounterunmanned/graphstyles.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme-header": {
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
      flex: 1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
    width: "100%",
  },
}));

const CustomerTimeInsightsModal = (props) => {
  const { handleClose, customerData, interval, setInterval } = props;
  const [selectedNav, setSelectedNav] = useState("graph");
  const [labels, setLabels] = useState([]);
  const [timeSights, setTimeSights] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [images, setImages] = useState([]);
  let userDetails = useSelector(authData);
  const [modalImage, setModalImage] = useState();
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();
  const [spin, setSpin] = useState(true);

  useEffect(() => {
    getGraphData();
    //eslint-disable-next-line
  }, [interval]);

  //   API to get graph and logs response
  const getGraphData = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/customer_time_insights/aggregate_list/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        if (result.data["result"] !== 0) {
          formatGraphData(result.data["result"]);
        }

        getDataGridData();
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  //   Formating response to get graph data
  const formatGraphData = (values) => {
    let labels = [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ];
    let timeinsights = [];

    for (const [key, value] of Object.entries(values)) {
      if (value.length !== 0) {
        let timespent = 0;
        for (let i = 0; i < value.length; i++) {
          timespent = timespent + value[i]["timespent"];
        }
        timeinsights.push(timespent);
      } else {
        timeinsights.push(0);
      }
    }
    setLabels(labels);
    setTimeSights(timeinsights);
  };

  //   API to get graph and logs response
  const getDataGridData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/customer_time_insights/aggregate_logs/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        if (result.data["result"].length !== 0) {
          tableViewConvertion(result.data["result"]);
        }
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const timeToDateCovert = (props) => {
    let date = new Date(props);

    return date;
  };

  const returnTime = (params) => {
    var decimalTime = params;
    decimalTime = decimalTime * 60;
    var hours = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;
    var minutes = Math.floor(decimalTime / 60);
    decimalTime = decimalTime - minutes * 60;
    var seconds = Math.round(decimalTime);
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return (
      <h3 className={Graph.bolddata}>
        {hours + ":" + minutes + ":" + seconds}
      </h3>
    );
  };

  const datagridvalue = (params) => {
    var decimalTime = params;
    decimalTime = decimalTime * 60;
    var hours = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;
    var minutes = Math.floor(decimalTime / 60);
    decimalTime = decimalTime - minutes * 60;
    var seconds = Math.round(decimalTime);
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds;
  };

  const tableViewConvertion = (value) => {
    let trows = [];
    let timages = [];

    if (value.length > 0) {
      for (let i = value.length - 1; i >= 0; i--) {
        if (value[i]["events"].length > 0) {
          for (let k = value[i]["events"].length - 1; k >= 0; k--) {
            let obj = {};
            let tobj = {};
            tobj.screenshot = value[i]["events"][k]["screenshot"];
            tobj.timestamp = `${moment
              .unix(value[i]["events"][k]["timestamp"])
              .format("LT")}`;
            tobj.cameranumber = value[i]["roi_name"];
            obj["id"] = trows.length + 1;
            let todayDate = `${value[i]["timestamp"]["day"]}-${value[i]["timestamp"]["month"]}-${value[i]["timestamp"]["year"]}`;
            todayDate = todayDate.split("-");
            let newDateTimestamp = new Date(
              todayDate[2],
              todayDate[1] - 1,
              todayDate[0]
            );
            obj["timeframe"] = `${moment
              .unix(value[i]["events"][k]["timestamp"])
              .format("LLL")}`;
            tobj.duration = value[i]["events"][k]["timespent"].toFixed(2);
            obj["camera"] = value[i]["roi_name"];
            obj["status"] = `${datagridvalue(
              value[i]["events"][k]["timespent"]
            )}`;
            obj["image"] = value[i]["events"][k]["screenshot"];
            trows.push(obj);
            timages.push(tobj);
          }
        }
      }
    }

    const tcolumns = [
      {
        field: "timeframe",
        headerName: "TIMEFRAME",
        flex: 1,
        headerClassName: "super-app-theme-header",
      },
      {
        field: "camera",
        headerName: "AREA NAME",
        flex: 1,
        headerClassName: "super-app-theme-header",
      },
      {
        field: "status",
        headerName: "Time Spent in Mins",
        flex: 1,
        headerClassName: "super-app-theme-header",
      },
      {
        field: "image",
        headerName: "IMAGES",
        flex: 0.5,
        headerClassName: "super-app-theme-header",

        renderCell: (params) => (
          <div
            className={Graph.image}
            onClick={() => {
              setModalImage(params.value);
              handleModalOpen();
            }}
          >
            <img
              src={ImagePlaceholder}
              alt="imageholder"
              className={Graph.imageplaceholder}
            />
          </div>
        ),
      },
    ];

    setRows(trows);
    setColumns(tcolumns);
    setImages(timages);
    setSpin(false);
  };

  const GraphComponent = () => {
    return (
      <div className={Graph.graphcontainer}>
        <div className={Graph.topcountplayholder}>
          {customerData.length !== 0
            ? customerData.map((value, i) => {
                return (
                  <div className={Graph.dataplayholder}>
                    <h4 className={Graph.data}>
                      {value.roi_name}:{" "}
                      <span className={Graph.bolddata}>
                        {returnTime(value.timespent)}
                      </span>
                    </h4>
                  </div>
                );
              })
            : null}
        </div>
        <div className={Graph.graphholder}>
          <BarChart labels={labels} timeSights={timeSights} />
        </div>
      </div>
    );
  };

  return (
    <div className={Graph.maincontainer}>
      <Modal
        open={openModal}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={openModal}>
          <ImageViewModal
            handleModalClose={handleModalClose}
            modalImage={modalImage}
          />
        </Fade>
      </Modal>
      <div className={Graph.topbar}>
        <h3 className={Graph.heading}>Customer Time Insights</h3>
        <div className={Graph.topnavigation}>
          <div
            className={
              selectedNav === "graph"
                ? Graph.navitemholderactive
                : Graph.navitemholder
            }
            onClick={() => setSelectedNav("graph")}
          >
            <h4 className={Graph.navitem}>Graphs</h4>
          </div>
          <div
            className={
              selectedNav === "logs"
                ? Graph.navitemholderactive
                : Graph.navitemholder
            }
            onClick={() => setSelectedNav("logs")}
          >
            <h4 className={Graph.navitem}>Logs</h4>
          </div>
          <div
            className={
              selectedNav === "images"
                ? Graph.navitemholderactive
                : Graph.navitemholder
            }
            onClick={() => setSelectedNav("images")}
          >
            <h4 className={Graph.navitem}>Images</h4>
          </div>
        </div>
        <div className={Graph.rightselection}>
          <select
            className={Graph.dayselect}
            onChange={(e) => {
              setRows([]);
              setColumns([]);
              setImages([]);
              setInterval(e.target.value);
            }}
            value={interval}
          >
            <option selected value="day">
              Today
            </option>
            <option value="yesterday">Yesterday</option>
            <option value="week">Last 7 Days</option>
            <option value="month">Last 30 Days</option>
          </select>
          <img
            src={Close}
            alt="closeimage"
            className={Graph.closeimage}
            onClick={() => handleClose()}
          />
        </div>
      </div>
      <div className={Graph.middlebar}>
        {selectedNav === "graph" ? (
          spin === false ? (
            <GraphComponent />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rect" width="100%" height={500} />{" "}
            </div>
          )
        ) : selectedNav === "logs" ? (
          spin === false ? (
            <div style={{ height: "60vh", width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                className={classes[".MuiDataGrid-root"]}
                rowHeight={100}
                disableSelectionOnClick
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rect" width="100%" height={500} />{" "}
            </div>
          )
        ) : (
          <div className={Graph.carouselholder}>
            {images.length !== 0 ? (
              <Carousel width={"55%"}>
                {images
                  .slice(-10)
                  .reverse()
                  .map((value, i) => {
                    return (
                      <div key={i}>
                        <img
                          src={value.screenshot}
                          alt="eventimage"
                          className={Graph.eventimage}
                        />
                        <p className="legend">
                          Action: Customer Time Isights
                          <br />
                          Roi Name: {value.cameranumber}
                          <br />
                          Timestamp: {value.timestamp}
                          <br />
                          Duration:{" "}
                          {value.duration !== null ? value.duration : 0} mins
                        </p>
                      </div>
                    );
                  })}
              </Carousel>
            ) : (
              <h4>Nothing to show here</h4>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerTimeInsightsModal;
