// Hygine features icons
import HandwashTracking from "../../../Assets/icons/handwashtracking.png";
import FloorMopping from "../../../Assets/icons/floormopping.png";
import HairnetDetection from "../../../Assets/icons/hairnetdetection.png";
import Facemask from "../../../Assets/icons/facemask.png";
import GlovesDetection from "../../../Assets/icons/gloves.png";
import Sanitation from "../../../Assets/icons/sanitation.png";
import Smoking from "../../../Assets/icons/smoking.png";

// Quality features icons
import AgeEstimation from "../../../Assets/icons/ageestimation.png";
import CustomerConversion from "../../../Assets/icons/customerconversion.png";
import PeakRush from "../../../Assets/icons/peakrush.png";
import PeopleCounter from "../../../Assets/icons/peoplecounter.png";
import StoreHeatmaps from "../../../Assets/icons/storeheatmaps.png";
import GenderRatio from "../../../Assets/icons/genderratio.png";
import CustomerSatisfaction from "../../../Assets/icons/customersatisfaction.png";
import CustomerUnAttended from "../../../Assets/icons/customerunattended.png";
import CustomerTime from "../../../Assets/icons/customertime.png";
import RoomOccupancy from "../../../Assets/icons/roomoccupancy.png";
import PrintIndex from "../../../Assets/icons/printindex.png";
import PrintEfficiency from "../../../Assets/icons/printefficiency.png";
import MannedBillingCounter from "../../../Assets/icons/mannedbillingcounter.png";
import KOTTracking from "../../../Assets/icons/kottracking.png";
import StoreEntrance from "../../../Assets/icons/storeentrance.png";
import PhoneUsage from "../../../Assets/icons/phoneusage.png";
import EmployeePresence from "../../../Assets/icons/employeepresence.png";
import StoreOpenClose from "../../../Assets/icons/employeepresence.png";

// Safety Features icons
import CCTVTampering from "../../../Assets/icons/cctvtampering.png";
import ElectricityWastage from "../../../Assets/icons/electricitywastage.png";
import SecurityTeam from "../../../Assets/icons/securityteam.png";
import SocialDistancing from "../../../Assets/icons/socialdistancing.png";
import PeopleGathering from "../../../Assets/icons/peoplegathering.png";
import ViolenceDetection from "../../../Assets/icons/violencedetection.png";
import FireDetection from "../../../Assets/icons/firedetection.png";
import IntrusionDetection from "../../../Assets/icons/intrusiondetection.png";
import NumberPlateRecognition from "../../../Assets/icons/numberplaterecognition.png";

// Hygine Feature images
import FacemaskImage from "../../../Assets/featureimages/hygine/facemask.png";
import FloorMoppingImage from "../../../Assets/featureimages/hygine/floormopping.png";
import GlovesImage from "../../../Assets/featureimages/hygine/gloves.png";
import HairnetImage from "../../../Assets/featureimages/hygine/hairnet.png";
import HandwashImage from "../../../Assets/featureimages/hygine/handwash.png";
import SanitationImage from "../../../Assets/featureimages/hygine/sanitation.png";
import SmokingDetectionImage from "../../../Assets/featureimages/hygine/smokingdetection.png";

// Safety feature images
import ElectricityWastageImage from "../../../Assets/featureimages/safety/electricity.png";
import CctvTamperingImage from "../../../Assets/featureimages/safety/cctvtampering.png";
import IntrusionImage from "../../../Assets/featureimages/safety/intrusion.png";
import NumberPlateImage from "../../../Assets/featureimages/safety/numberplate.png";
import PeopleGatheringImage from "../../../Assets/featureimages/safety/peoplegathering.png";
import SecurityPresence from "../../../Assets/featureimages/safety/securitypresence.png";
import SmokeImage from "../../../Assets/featureimages/safety/smoke.png";
import SocialDistancingImage from "../../../Assets/featureimages/safety/socialdistancing.png";
import ViolanceImage from "../../../Assets/featureimages/safety/violence.png";

//Quality feature images
import AgeEstimationImage from "../../../Assets/featureimages/qualtiy/ageestimation.png";
import CloseTimingsImage from "../../../Assets/featureimages/qualtiy/closetimings.png";
import CustomerUnAttendedImage from "../../../Assets/featureimages/qualtiy/customerunattended.png";
import CustomerConversionImage from "../../../Assets/featureimages/qualtiy/customerconversion.png";
import CustomerSatisfactionImage from "../../../Assets/featureimages/qualtiy/customersatisfaction.png";
import EmployeePresenceImage from "../../../Assets/featureimages/qualtiy/employeepresence.png";
import GenderImage from "../../../Assets/featureimages/qualtiy/gender.png";
import HeatmapImage from "../../../Assets/featureimages/qualtiy/heatmap.png";
import KotTrackingImage from "../../../Assets/featureimages/qualtiy/kottracking.png";
import PeopleCounterImage from "../../../Assets/featureimages/qualtiy/peoplecounter.png";
import PhoneUsageImage from "../../../Assets/featureimages/qualtiy/phoneusage.png";
import PrintEfficiencyImage from "../../../Assets/featureimages/qualtiy/printefficiency.png";
import PrintIndexImage from "../../../Assets/featureimages/qualtiy/printindex.png";
import RoomOccupancyImage from "../../../Assets/featureimages/qualtiy/roomoccupancy.png";
import RushHourImage from "../../../Assets/featureimages/qualtiy/rushhour.png";
import TimeInsightsImage from "../../../Assets/featureimages/qualtiy/timeinsights.png";
import BillingCounterImage from "../../../Assets/featureimages/qualtiy/unmannedbillingcounter.png";
import EntranceUnmannedImage from "../../../Assets/featureimages/qualtiy/unmannedentrance.png";

export const hyginefeatures = [
  {
    featurename: "Handwash Tracking",
    featureicon: HandwashTracking,
    featuredescription:
      "Frequency and duration of handwash is monitored in accordance with the 20 second rule.",
    featureimage: HandwashImage,
    featurecode: "KHD.HWT",
    oneliner: "Count of hand wash events and 20s rule events.",
  },
  {
    featurename: "Floor Mopping Monitoring",
    featureicon: FloorMopping,
    featuredescription: "Monitors the regularity of floor mopping.",
    featureimage: FloorMoppingImage,
    featurecode: "KHD.FMM",
    oneliner: "Completion of floor mopping events based on schedule.",
  },
  {
    featurename: "Hairnet Detection",
    featureicon: HairnetDetection,
    featuredescription: "",
    featureimage: HairnetImage,
    featurecode: "KPPE.HD",
    oneliner: "Hairnets undetected and its effect on compliance score.",
  },
  {
    featurename: "Face Mask Detection",
    featureicon: Facemask,
    featuredescription: "All aspects of PPE are monitored timely.",
    featureimage: FacemaskImage,
    featurecode: "KPPE.FMD",
    oneliner: "Facemasks undetected and its effect on compliance score.",
  },
  {
    featurename: "Gloves Detection",
    featureicon: GlovesDetection,
    featuredescription: "",
    featureimage: GlovesImage,
    featurecode: "KPPE.GD",
    oneliner: "Gloves undetected and its effect on compliance score.",
  },
  {
    featurename: "Sanitation",
    featureicon: Sanitation,
    featuredescription:
      "Checks the overall cleanliness based on the hygiene parameters by OurEye.",
    featureimage: SanitationImage,
    featurecode: "CS.SA",
    oneliner: "Score out of 5 based on overall sanitation score.",
  },
  {
    featurename: "Smoking Detection",
    featureicon: Smoking,
    featuredescription: "Checks whether the premise is smoke free or not.",
    featureimage: SmokingDetectionImage,
    featurecode: "SEMP.SD",
    oneliner: "Smoking detected inside and outside the smoking zone.",
  },
];

export const qualityfeatures = [
  {
    featurename: "Age Estimation",
    featureicon: AgeEstimation,
    featuredescription: "Infers the age of customers entering the store.",
    featureimage: AgeEstimationImage,
    featurecode: "CI.AE",
    oneliner: "Estimation of age group among foot fall.",
  },
  {
    featurename: "Customer Coversion Monitoring",
    featureicon: CustomerConversion,
    featuredescription:
      "A measure of the number of customers who buy the product vs the ones who just browse through.",
    featureimage: CustomerConversionImage,
    featurecode: "CI.CCM",
    oneliner: "Number of customers converted to make a payment.",
  },
  // {
  //   featurename: "Peak Rush Hour Monitoring",
  //   featureicon: PeakRush,
  //   featuredescription:
  //     "Informs about a specific period of time when customer crowding is the highest.",
  //   featureimage: RushHourImage,
  //   featurecode: "CI.PRHM",
  //   oneliner: ""
  // },
  {
    featurename: "Foot Fall",
    featureicon: PeopleCounter,
    featuredescription:
      "Gives a headcount of the number of people traversing the store.",
    featureimage: PeopleCounterImage,
    featurecode: "CI.PC",
    oneliner: "Number of people entered and highest foot fall.",
  },
  {
    featurename: "Store Heatmaps",
    featureicon: StoreHeatmaps,
    featuredescription:
      "Identifies areas in a store where maximum customer engagement happens.",
    featureimage: HeatmapImage,
    featurecode: "CI.SH",
    oneliner: "Highest foot fall at the particular area selected",
  },
  {
    featurename: "Gender Ratio Monitoring",
    featureicon: GenderRatio,
    featuredescription:
      "Tells the gender ratio of the customers who enter the store.",
    featureimage: GenderImage,
    featurecode: "CI.GRM",
    oneliner: "Number of male and female foot fall.",
  },
  {
    featurename: "Customer Satisfaction Index",
    featureicon: CustomerSatisfaction,
    featuredescription:
      "Infers the emotions of customers based on quality, customer service and behavioral patterns.",
    featureimage: CustomerSatisfactionImage,
    featurecode: "CI.CSI",
    oneliner: "Customer satisfaction percentage based on foot fall.",
  },
  {
    featurename: "Customer Unattended",
    featureicon: CustomerUnAttended,
    featuredescription:
      "Real time notifications regarding unattended customers.",
    featureimage: CustomerUnAttendedImage,
    featurecode: "CI.CU",
    oneliner: "Number of customers left unattended by your employee.",
  },
  {
    featurename: "Customer Time Insights",
    featureicon: CustomerTime,
    featuredescription:
      "Measure of the average wait time of the customer at different counters.",
    featureimage: CloseTimingsImage,
    featurecode: "CI.CTI",
    oneliner: "Amount of time spent by the customer at a particular area.",
  },
  {
    featurename: "Room Occupancy",
    featureicon: RoomOccupancy,
    featuredescription:
      "Gives a body count of all the people present in the range of the camera at all times.",
    featureimage: RoomOccupancyImage,
    featurecode: "WI.RO",
    oneliner: "Average number of people present at a given time.",
  },
  // {
  //   featurename: "Print Index",
  //   featureicon: PrintIndex,
  //   featuredescription: "",
  //   featureimage: PrintIndexImage,
  //   featurecode: "WI.PI",
  // },
  // {
  //   featurename: "Print Efficiency",
  //   featureicon: PrintEfficiency,
  //   featuredescription: "",
  //   featureimage: PrintEfficiencyImage,
  //   featurecode: "WI.PE",
  // },
  {
    featurename: "Manned/Unmanned Area",
    featureicon: MannedBillingCounter,
    featuredescription: "Monitors whether the counters are manned or not.",
    featureimage: BillingCounterImage,
    featurecode: "OCT.MBC",
    oneliner: "Area left unmanned when customer is present.",
  },
  // {
  //   featurename: "KOT Tracking",
  //   featureicon: KOTTracking,
  //   featuredescription: "",
  //   featureimage: KotTrackingImage,
  //   featurecode: "OCT.KT",
  // },
  {
    featurename: "Store Entrance Unmanned",
    featureicon: StoreEntrance,
    featuredescription: "Checks if the store entrance is guarded or not.",
    featureimage: EntranceUnmannedImage,
    featurecode: "SEPM.SEU",
    oneliner: "Store entrance is left unmanned when a customer is entering.",
  },
  // {
  //   featurename: "Phone Usage",
  //   featureicon: PhoneUsage,
  //   featuredescription:
  //     "Tracks the instances when the employee was on phone while being on duty.",
  //   featureimage: PhoneUsageImage,
  //   featurecode: "SEPM.PU",
  // },
  {
    featurename: "Employee Presence Monitoring",
    featureicon: EmployeePresence,
    featuredescription:
      "Monitors whether the customer is attended by the employee or not. Gives the employee presence around the customer.",
    featureimage: EmployeePresenceImage,
    featurecode: "SEPM.EP",
    oneliner: "Presence of employees with customers around the store. ",
  },
  {
    featurename: "Store Open/Close Timings",
    featureicon: StoreOpenClose,
    featuredescription: "Tracks the store opening and closing time. ",
    featureimage: TimeInsightsImage,
    featurecode: "SEPM.OCT",
    oneliner: "Store open time and close time as per schedule.",
  },
];

export const safetyfeatures = [
  {
    featurename: "CCTV Tampering",
    featureicon: CCTVTampering,
    featuredescription: "Monitors tampering or rash handling of CCTV cameras.",
    featureimage: CctvTamperingImage,
    featurecode: "TA.CCTV",
    oneliner: "Detection of unauthorized CCTV disturbance.",
  },
  {
    featurename: "Electricity Wastage Detection",
    featureicon: ElectricityWastage,
    featuredescription:
      "Checks whether the electrical appliances are still ON even after use. Gives the average electricity unit wastage.",
    featureimage: ElectricityWastageImage,
    featurecode: "EM.EWD",
    oneliner: "Lights and other electronics using electricity unnecessarily.",
  },
  {
    featurename: "Security Team Presence",
    featureicon: SecurityTeam,
    featuredescription:
      "Monitors if the security personnel are present at the time of duty or not.",
    featureimage: SecurityPresence,
    featurecode: "ST.STP",
    oneliner: "Presence of security team at gates as per schedule.",
  },
  {
    featurename: "Social Distancing",
    featureicon: SocialDistancing,
    featuredescription: "Monitors the adherence of social distancing norms.",
    featureimage: SocialDistancingImage,
    featurecode: "CS.SD",
    oneliner: "Detection of social distancing not being followed.",
  },
  {
    featurename: "People Gathering",
    featureicon: PeopleGathering,
    featuredescription:
      "Alerts if crowding exceeds the threshold of the store.",
    featureimage: PeopleGatheringImage,
    featurecode: "CS.PG",
    oneliner:
      "Detection of people gathering unnecessarily at a particular area.",
  },
  {
    featurename: "Violence Detection",
    featureicon: ViolenceDetection,
    featuredescription:
      "Checks if there are outbursts of violent activity in the premises.",
    featureimage: ViolanceImage,
    featurecode: "SAD.VD",
    oneliner: "Detection of violence as per schedule.",
  },
  {
    featurename: "Fire/Smoke Detection",
    featureicon: FireDetection,
    featuredescription: "Detects any signs of fire and smoke in the store.",
    featureimage: SmokeImage,
    featurecode: "ST.FD",
    oneliner: "Detection of fire and smoke at a particular area.",
  },
  {
    featurename: "Intrusion Detection/Perimeter Control",
    featureicon: IntrusionDetection,
    featuredescription: "Real time intrusion detection in the premises.",
    featureimage: IntrusionImage,
    featurecode: "ST.ID",
    oneliner: "Detection of person or unauthorized objects at a given area.",
  },
  {
    featurename: "Automatic Number Plate Recognition",
    featureicon: NumberPlateRecognition,
    featuredescription: "",
    featureimage: NumberPlateImage,
    featurecode: "DL.NPd",
    oneliner: "Number Plate detection of all vehicles based on selected area.",
  },
];
