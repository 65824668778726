// Dependencies imports
import React from "react";

// Local imports
import SideNav from "../sidenav/sidenav";
import Header from "../headers/header";
import Hygiene from "../Components/Screens/DashboardScreens/hygiene/hygiene";

// Style imports
import Lay from "./layout.module.scss";

const HygieneLayout = () => {
  return (
    <div className={Lay.maincontainer}>
      <Header />
      <div className={Lay.nextcontainer}>
        <SideNav />
        <Hygiene />
      </div>
    </div>
  );
};

export default HygieneLayout;
