// Dependencies imports
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useSelector } from "react-redux";

// Local imports
import { ReactComponent as HygieneBlue } from "../Assets/icons/hygieneBlue.svg";
import { ReactComponent as HygieneBlack } from "../Assets/icons/hygieneblack.svg";
import { ReactComponent as QualityBlue } from "../Assets/icons/qualityblue.svg";
import { ReactComponent as QualityBlack } from "../Assets/icons/qualityblack.svg";
import { ReactComponent as SafetyBlue } from "../Assets/icons/safetyBlue.svg";
import { ReactComponent as SafetyBlack } from "../Assets/icons/safetyblack.svg";
import { ReactComponent as Add } from "../Assets/icons/add.svg";
import { ReactComponent as HelpIcon } from "../Assets/icons/helpicon.svg";
import { ReactComponent as SettingsIcon } from "../Assets/icons/settings.svg";
import { ReactComponent as PlayBackIcon } from "../Assets/icons/playbackicon.svg";
import { authData } from "../redux/auth/authSlice";

// Style import
import Side from "./sidenav.module.scss";

const SideNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let userDetails = useSelector(authData);
  const [safetyFeatures, setSafetyFeatures] = useState([]);
  const [hygieneFeatures, setHygieneFeatures] = useState([]);
  const [qualityFeatures, setQualityFeatures] = useState([]);
  const [hygieneCollapsed, setHygieneCollapsed] = useState(
    location.pathname === "/hygiene" ? true : false
  );
  const [safetyCollapsed, setSafetyCollapsed] = useState(
    location.pathname === "/safety" ? true : false
  );
  const [qualityCollapsed, setQualityCollapsed] = useState(
    location.pathname === "/quality" ? true : false
  );

  useEffect(() => {
    getFeatureList();
    //eslint-disable-next-line
  }, []);

  const getFeatureList = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/cameras/find/features_list/${userDetails.outlet_code}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("resultdata", result.data);
        setQualityFeatures(result.data["quality"]);
        setHygieneFeatures(result.data["hygiene"]);
        setSafetyFeatures(result.data["safety"]);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        swal({
          title: "Oops!",
          text: "Something went wrong!",
          icon: "error",
          buttons: false,
        });
      });
  };

  return (
    <ProSidebar
      // toggled={toggled}
      breakPoint="md"
      className={Side.sidemain}
    >
      <SidebarContent className={Side.menucontent}>
        <Menu className={Side.mainmenu}>
          <SubMenu
            open={hygieneCollapsed}
            defaultOpen={true}
            onOpenChange={() => {
              setHygieneCollapsed(hygieneCollapsed === true ? false : true);
              setQualityCollapsed(false);
              setSafetyCollapsed(false);
              navigate("/hygiene");
            }}
            title={
              <h4
                className={
                  hygieneCollapsed ? Side.menuheadingactive : Side.menuheading
                }
              >
                Hygiene ({hygieneFeatures.length})
              </h4>
            }
            icon={
              hygieneCollapsed ? (
                <HygieneBlue className={Side.icon} />
              ) : (
                <HygieneBlack className={Side.icon} />
              )
            }
            innerSubMenuArrows={true}
            className={
              hygieneCollapsed
                ? Side.submenucontaineractive
                : Side.submenucontainer
            }
          >
            {hygieneFeatures.includes("KHD.HWT") ? (
              <MenuItem className={Side.submenu}>Handwash Detection</MenuItem>
            ) : null}
            {hygieneFeatures.includes("KHD.FMM") ? (
              <MenuItem className={Side.submenu}>Floor Mopping</MenuItem>
            ) : null}
            {hygieneFeatures.includes("KPPE.HD") ? (
              <MenuItem className={Side.submenu}>Hairnet Detection</MenuItem>
            ) : null}
            {hygieneFeatures.includes("KPPE.FMD") ? (
              <MenuItem className={Side.submenu}>Face Mask Detection</MenuItem>
            ) : null}
            {hygieneFeatures.includes("KPPE.GD") ? (
              <MenuItem className={Side.submenu}>Gloves Detection</MenuItem>
            ) : null}
            {hygieneFeatures.includes("SEMP.SD") ? (
              <MenuItem className={Side.submenu}>Smoking Detection</MenuItem>
            ) : null}
          </SubMenu>
          <SubMenu
            open={qualityCollapsed}
            onOpenChange={() => {
              setQualityCollapsed(qualityCollapsed === true ? false : true);
              setHygieneCollapsed(false);
              setSafetyCollapsed(false);
              navigate("/quality");
            }}
            title={
              <h4
                className={
                  qualityCollapsed ? Side.menuheadingactive : Side.menuheading
                }
              >
                Quality ({qualityFeatures.length})
              </h4>
            }
            icon={
              qualityCollapsed ? (
                <QualityBlue className={Side.icon} />
              ) : (
                <QualityBlack className={Side.icon} />
              )
            }
            className={
              qualityCollapsed
                ? Side.submenucontaineractive
                : Side.submenucontainer
            }
          >
            {qualityFeatures.includes("CI.AE") ? (
              <MenuItem className={Side.submenu}>
                Age Estimation <Link to="/hygine" />
              </MenuItem>
            ) : null}
            {qualityFeatures.includes("CI.CCM") === true ? (
              <MenuItem className={Side.submenu}>
                Customer Coversion Monitoring
              </MenuItem>
            ) : null}
            {/* <MenuItem className={Side.submenu}>
              Peak Rush Hour Monitoring
            </MenuItem> */}
            {qualityFeatures.includes("CI.PC") === true ? (
              <MenuItem className={Side.submenu}>Foot Fall</MenuItem>
            ) : null}
            {/* <MenuItem className={Side.submenu}>Store Heatmaps</MenuItem> */}
            {qualityFeatures.includes("CI.GRM") === true ? (
              <MenuItem className={Side.submenu}>
                Gender Ratio Monitoring
              </MenuItem>
            ) : null}
            {qualityFeatures.includes("CI.CSI") ? (
              <MenuItem className={Side.submenu}>
                Customer Satisfaction Index
              </MenuItem>
            ) : null}
            {qualityFeatures.includes("CI.CU") ? (
              <MenuItem className={Side.submenu}>Customers Unattended</MenuItem>
            ) : null}
            {qualityFeatures.includes("CI.CTI") ? (
              <MenuItem className={Side.submenu}>
                Customers Time Insights
              </MenuItem>
            ) : null}
            {qualityFeatures.includes("WI.RO") ? (
              <MenuItem className={Side.submenu}>Room Occupancy</MenuItem>
            ) : null}
            {/* <MenuItem className={Side.submenu}>Print Index</MenuItem>
            <MenuItem className={Side.submenu}>Print Efficiency</MenuItem> */}
            {qualityFeatures.includes("OCT.MBC") ? (
              <MenuItem className={Side.submenu}>
                Manned/Unmanned Billing Counter
              </MenuItem>
            ) : null}
            {/* <MenuItem className={Side.submenu}>KOT Tracking</MenuItem> */}
            {qualityFeatures.includes("SEPM.SEU") ? (
              <MenuItem className={Side.submenu}>
                Store Entrance Unmanned
              </MenuItem>
            ) : null}
            {/* <MenuItem className={Side.submenu}>Phone Usage</MenuItem> */}
            {qualityFeatures.includes("SEPM.EP") ? (
              <MenuItem className={Side.submenu}>
                Employee Presence Monitoring
              </MenuItem>
            ) : null}
            {qualityFeatures.includes("SEPM.OCT") ? (
              <MenuItem className={Side.submenu}>
                Store Open/Close Timings
              </MenuItem>
            ) : null}
          </SubMenu>
          <SubMenu
            open={safetyCollapsed}
            onOpenChange={() => {
              setSafetyCollapsed(safetyCollapsed === true ? false : true);
              setHygieneCollapsed(false);
              setQualityCollapsed(false);
              navigate("/safety");
            }}
            title={
              <h4
                className={
                  safetyCollapsed ? Side.menuheadingactive : Side.menuheading
                }
              >
                Safety ({safetyFeatures.length})
              </h4>
            }
            icon={
              safetyCollapsed ? (
                <SafetyBlue className={Side.icon} />
              ) : (
                <SafetyBlack className={Side.icon} />
              )
            }
            className={
              safetyCollapsed
                ? Side.submenucontaineractive
                : Side.submenucontainer
            }
          >
            {safetyFeatures.includes("TA.CCTV") ? (
              <MenuItem className={Side.submenu}>
                CCTV Tampering <Link to="/hygine" />
              </MenuItem>
            ) : null}
            {safetyFeatures.includes("EM.EWD") ? (
              <MenuItem className={Side.submenu}>
                Electricity Wastage Detection
              </MenuItem>
            ) : null}
            {safetyFeatures.includes("ST.STP") ? (
              <MenuItem className={Side.submenu}>
                Security Team Presence
              </MenuItem>
            ) : null}
            {safetyFeatures.includes("CS.SD") ? (
              <MenuItem className={Side.submenu}>Socail Distancing</MenuItem>
            ) : null}
            {safetyFeatures.includes("CS.PG") ? (
              <MenuItem className={Side.submenu}>People Gathering</MenuItem>
            ) : null}
            {safetyFeatures.includes("SAD.VD") ? (
              <MenuItem className={Side.submenu}>Violence Detection</MenuItem>
            ) : null}
            {safetyFeatures.includes("ST.FD") ? (
              <MenuItem className={Side.submenu}>Fire/Smoke Detection</MenuItem>
            ) : null}
            {safetyFeatures.includes("ST.ID") ? (
              <MenuItem className={Side.submenu}>
                Intrusion Detection/Perimeter Control
              </MenuItem>
            ) : null}
            {safetyFeatures.includes("DL.NPd") ? (
              <MenuItem className={Side.submenu}>
                Automatic Number Plate Recognition
              </MenuItem>
            ) : null}
          </SubMenu>
        </Menu>
      </SidebarContent>
      <SidebarFooter className={Side.sidenavfooter}>
        <div
          style={{
            padding: "20px 0px",
          }}
        >
          <Menu
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <MenuItem
              icon={<PlayBackIcon className={Side.icon} />}
              className={Side.submenucontainer}
            >
              Playback <Link to="/playback" />
            </MenuItem>
          </Menu>
          {/* <Menu
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <MenuItem
              icon={<Add className={Side.icon} />}
              className={Side.submenucontainer}
            >
              Add Outlet <Link to="/" />
            </MenuItem>
          </Menu>
          <Menu
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <MenuItem
              icon={<SettingsIcon className={Side.icon} />}
              className={Side.submenucontainer}
            >
              Settings <Link to="/" />
            </MenuItem>
          </Menu>

          <Menu
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <MenuItem
              icon={<HelpIcon className={Side.icon} />}
              className={Side.submenucontainer}
            >
              Help & Support
              <Link to="/" />
            </MenuItem>
          </Menu> */}
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SideNav;
