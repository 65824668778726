// Dependencies imports
import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import VideoThumbnail from "react-video-thumbnail";
import axios from "axios";
import qs from "qs";
import VideoNail from "../../../Assets/images/noimage.png";
import { useSelector, useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

// Local imports
import { stateUpdate, authData } from "../../../redux/auth/authSlice";

// Style imports
import Live from "./playback.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const VideoCarousel = () => {
  const [response, setResponse] = useState({});
  const [videoData, setVideoData] = useState([]);
  const [streamData, setStreamData] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState();
  const [spin, setSpin] = useState(false);
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getCameraDetails();

    //eslint-disable-next-line
  }, []);

  // API call to get camera details as per outlet code and user email
  const getCameraDetails = () => {
    console.log("userDetails", userDetails);
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/cameras/details/${userDetails.outlet_code.toString()}/${userDetails.email.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log(result.data);

        let temp = [];
        for (const property in result.data.thumbnail_urls) {
          let obj = {};
          obj["cameraname"] = `${property}`;
          obj["thumbnail"] = `${result.data.thumbnail_urls[property]}`;
          temp.push(obj);
        }
        console.log("camerathumb", temp);
        let userData = { ...userDetails };
        userData.cameraDetails = {};
        for (let i = 0; i < temp.length; i++) {
          userData.cameraDetails[temp[i].cameraname] = temp[i].thumbnail;
        }
        dispatch(stateUpdate(userData));
        getVideo();
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        setSpin(false);
      });
  };

  const giveCameraNames = (props) => {
    if (props === "cam_1") {
      return "Camera 1";
    } else if (props === "cam_2") {
      return "Camera 2";
    } else if (props === "cam_3") {
      return "Camera 3";
    } else if (props === "cam_4") {
      return "Camera 4";
    } else if (props === "cam_5") {
      return "Camera 5";
    } else if (props === "cam_6") {
      return "Camera 6";
    } else if (props === "cam_7") {
      return "Camera 7";
    } else if (props === "cam_8") {
      return "Camera 8";
    } else if (props === "cam_9") {
      return "Camera 9";
    } else if (props === "cam_10") {
      return "Camera 10";
    } else if (props === "cam_11") {
      return "Camera 11";
    } else if (props === "cam_12") {
      return "Camera 12";
    } else if (props === "cam_13") {
      return "Camera 13";
    } else if (props === "cam_14") {
      return "Camera 14";
    } else if (props === "cam_15") {
      return "Camera 15";
    } else if (props === "cam_16") {
      return "Camera 16";
    } else if (props === "cam_17") {
      return "Camera 17";
    } else if (props === "cam_18") {
      return "Camera 18";
    } else if (props === "cam_19") {
      return "Camera 19";
    } else if (props === "cam_20") {
      return "Camera 20";
    } else if (props === "cam_21") {
      return "Camera 21";
    }
  };

  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const getVideo = () => {
    setSpin(true);
    var data = qs.stringify({
      outlet_code: userDetails.outlet_code.toString(),
      video_recoder_number: "1",
    });

    var config = {
      method: "get",
      url: `https://api.oc3.oureye.ai/stream_recordings/all/${userDetails.outlet_code}/1/`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config).then((res) => {
      const videoArray = res.data;
      console.log("videoarray", res.data);
      setResponse(videoArray);
      setVideoData(Object.keys(videoArray));
      console.log("videodata", videoData);
      setSpin(false);
    });
  };

  const customRenderThumb = (children) =>
    streamData.map((item) => {
      return (
        <img src={userDetails.cameraDetails[selectedCamera]} alt="tumbnail" />
        // <VideoThumbnail
        //   videoUrl={userDetails.cameraDetails[selectedCamera]}
        //   thumbnailHandler={(thumbnail) => console.log(thumbnail)}
        // />
      );
    });

  return (
    <div>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Live.selectholder}>
        <select
          id="camselect"
          className={Live.camselect}
          onChange={(e) => {
            setSpin(true);
            setStreamData([]);
            setTimeout(() => {
              setSelectedCamera(e.target.value);
              console.log("targeted cam", e.target.value);
              console.log("camera details", userDetails.cameraDetails);
              let temp = response[e.target.value];
              let dummyArray = temp.slice(-10);
              let slicearray = [];
              for (let i = dummyArray.length - 1; i >= 0; i--) {
                slicearray.push(dummyArray[i]);
              }
              setStreamData(slicearray);
              setSpin(false);
            }, 2000);
          }}
        >
          <option for="camselect" disabled selected>
            Select Camera
          </option>
          {videoData.map((value, i) => {
            return (
              <option for="cameselect" value={value} key={i}>
                {giveCameraNames(value)}
              </option>
            );
          })}
        </select>
        {/* <input type="date" className={Live.camselectdate} /> */}
      </div>
      {spin === false ? (
        streamData.length !== 0 ? (
          <div className={Live.carouselholder}>
            <Carousel renderThumbs={customRenderThumb}>
              {streamData.map((value, i) => {
                return (
                  <div key={i}>
                    <video
                      width="100%"
                      height="500"
                      controls
                      className={Live.innervideoholder}
                    >
                      <source src={value} type="video/mp4"></source>
                    </video>
                  </div>
                );
              })}
            </Carousel>
          </div>
        ) : (
          <div className={Live.selectcameras}>
            <br />
            <br />
            <h4 className={Live.selectheading}>Please select a camera</h4>
          </div>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rect" width="100%" height={600} />{" "}
        </div>
      )}
    </div>
  );
};

export default VideoCarousel;
