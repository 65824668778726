// Dependencies imports
import React from "react";

// Local imports
import { ReactComponent as BiggerScreenImage } from "../../../Assets/images/biggerscreen.svg";
import logo from "../../../Assets/logo/logo.png";

// Style imports
import Big from "./biggerscreenprompt.module.scss";

const BiggerScreen = () => {
  return (
    <h3 className={Big.maincontainer}>
      <img className={Big.logoimage} src={logo} alt="logo" />
      <BiggerScreenImage className={Big.biggerimage} />
      <h4 className={Big.graphtext}>Oops!</h4>
      <h4 className={Big.textbelowoops}>
        This screen is too small for the dashboard. Please view on a bigger
        screen.
      </h4>
    </h3>
  );
};

export default BiggerScreen;
