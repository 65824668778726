import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props) => {
  const { labels, dataSetMale, dataSetFemale } = props;

  const options = {
    scales: {
      y: {
        title: { display: true, text: "Count" },
      },
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Hourly Average",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Male",
        data: dataSetMale,
        backgroundColor: "#226BF6",
        borderColor: "rgb(53, 162, 235)",
      },
      {
        label: "Female",
        data: dataSetFemale,
        backgroundColor: "#FF5F49",
        borderColor: "rgb(255, 99, 132)",
      },
    ],
  };

  return <Line data={data} height={300} options={options} />;
};

export default BarChart;
