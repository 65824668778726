// Dependencies imports
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// Local imports
import Signup from "./Components/Screens/LoginScreens/signup";
import Login from "./Components/Screens/LoginScreens/login";
import HygieneLayout from "./layouts/hygienelayout";
import SafetyLayout from "./layouts/safetylayout";
import QualityLayout from "./layouts/qualitylayout";
import { stateUpdate, authData } from "./redux/auth/authSlice";
import SelectFeatures from "./Components/Screens/OnboardingScreens/selectfeatures";
import SelectCameras from "./Components/Screens/OnboardingScreens/selectcameras";
import Onboarding from "./Components/Screens/LoginScreens/onboarding";
import ContinueOnPhone from "./Components/Screens/OnboardingScreens/continueonphone";
import PlaybackLayout from "./layouts/playbacklayout";
import BiggerScreen from "./Components/Screens/usebiggerscreens/biggerscreenprompt";

// Style imports
import "./App.scss";

// start of main app component
function App() {
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const breakpointWidth = 1000;
  const breakpoinHeight = 700;

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);
    if (userDetails !== 0) {
      getOnboardingState();
    } else {
    }

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);

    //eslint-disable-next-line
  }, []);

  const getOnboardingState = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/onboard/onboarding_state/${userDetails.email.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        let userData = { ...userDetails };
        userData.onboarding_state = result.data;
        dispatch(stateUpdate(userData));
      })
      .catch(function (error) {});
  };

  return (
    <Router>
      {width < breakpointWidth || height < breakpoinHeight ? (
        <Routes>
          <Route exact path="/" element={<BiggerScreen />} />
          <Route exact path="*" element={<BiggerScreen />} />
        </Routes>
      ) : userDetails === 0 ? (
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="*" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
        </Routes>
      ) : userDetails.onboarding_state === 1 ? (
        <Routes>
          <Route exact path="/" element={<Onboarding />} />
          <Route exact path="*" element={<Onboarding />} />
          <Route exact path="/outletdetails" element={<Onboarding />} />
        </Routes>
      ) : userDetails.onboarding_state === 2 ? (
        <Routes>
          <Route exact path="/" element={<ContinueOnPhone />} />
          <Route exact path="*" element={<ContinueOnPhone />} />
          <Route exact path="/continueonphone" element={<ContinueOnPhone />} />
        </Routes>
      ) : userDetails.onboarding_state === 3 ||
        userDetails.onboarding_state === 4 ? (
        <Routes>
          <Route exact path="/" element={<SelectCameras />} />
          <Route exact path="*" element={<SelectCameras />} />
          <Route exact path="/selectcameras" element={<SelectCameras />} />
          <Route exact path="/selectfeatures" element={<SelectFeatures />} />
        </Routes>
      ) : (
        userDetails.onboarding_state === 5 && (
          <Routes>
            <Route exact path="/" element={<HygieneLayout />} />
            <Route exact path="*" element={<HygieneLayout />} />
            <Route exact path="/hygiene" element={<HygieneLayout />} />
            <Route exact path="/safety" element={<SafetyLayout />} />
            <Route exact path="/quality" element={<QualityLayout />} />
            <Route exact path="/selectfeatures" element={<SelectFeatures />} />
            <Route exact path="/selectcameras" element={<SelectCameras />} />
            <Route exact path="/playback" element={<PlaybackLayout />} />
          </Routes>
        )
      )}
    </Router>
  );
}

export default App;
