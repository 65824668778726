// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import InfoIcon from "../../../../../Assets/icons/infoicon.png";
import { authData } from "../../../../../redux/auth/authSlice";
import AgeEstimationModal from "./ageestimationmodal";

// Style imports
import Card from "../../hygiene/cardstyle.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const AgeEstimationCard = (props) => {
  const { interval, setInterval } = props;
  let userDetails = useSelector(authData);
  const [highestAge, setHighestAge] = useState();
  const [highestAgeGroup, setHighestAgeGroup] = useState();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getCardData();
    //eslint-disable-next-line
  }, [interval]);

  const getCardData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/demographics/aggregate_list_age/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("age estimation response", result.data);
        if (result.data["result"].length !== 0) {
          let ageGroupObj = result.data["result"][0];
          const maxValue = Object.entries(ageGroupObj).sort(
            (x, y) => y[1] - x[1]
          )[0];
          setHighestAge(maxValue[1]);
          console.log("maxvalue", maxValue);
          let highestAgeGroup = maxValue[0];
          console.log("highest age group", highestAgeGroup);
          setHighestAgeGroup(maxValue[1] !== 0 ? highestAgeGroup : "None");
        }
      })
      .catch(function (error) {});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={Card.mainbigcontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <AgeEstimationModal
            handleClose={handleClose}
            highestAge={highestAge}
            highestAgeGroup={highestAgeGroup}
            interval={interval}
            setInterval={setInterval}
          />
        </Fade>
      </Modal>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Age Estimation</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      <div className={Card.middleholder}>
        <div className={Card.leftbigholder}>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>Larger age group count is: </h5>
            <h5 className={Card.datavalueblue}>{highestAge}</h5>
          </div>
          <div className={Card.eventsholder}>
            <h5 className={Card.featuredetails}>
              Highest Age Group Detected are :{" "}
            </h5>
            <h5 className={Card.datavalueblue}>{highestAgeGroup}</h5>
          </div>
          <br />
          <button className={Card.learnmore} onClick={() => handleOpen()}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgeEstimationCard;
