// Dependencies imports
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

// Local imports
// import { ReactComponent as BulbIcon } from "../../../../Assets/icons/yellowbulb.svg";
// import { ReactComponent as AnalyticsIcon } from "../../../../Assets/icons/analyticsicon.svg";
import SocialDistancingCard from "./socialdistancingcard";
import PeopleGatheringCard from "./peoplegatheringccard";
import SecurityTeamPresenceCard from "./securityteampresencecard";
import IntrutionDetectionCard from "./intrusion/intrusiondetectioncard";
import FireAndSmokeDetectionCard from "./fireandsmokedetectioncard";
import ViolenceDetectionCard from "./violencedetectioncard";
import CctvTamperingCard from "./cctvtamperingcard";
import AnprCard from "./anprcard";
import ElectricityWastageCard from "./electricitywastagecard";
import AddFeatureCard from "../hygiene/addfeature";
import { authData } from "../../../../redux/auth/authSlice";

// Style imports
import Hy from "../hygiene/hygiene.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

// // Styles for carousel responsiveness
// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 1,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

const Safety = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let userDetails = useSelector(authData);
  const [safetyFeatures, setSafetyFeatures] = useState([]);
  const [hygieneCount, setHygieneCount] = useState(0);
  const [spin, setSpin] = useState(true);
  const [interval, setInterval] = useState("day");
  const [qualityCount, setQualityCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    getFeatureList();
    //eslint-disable-next-line
  }, []);

  const getFeatureList = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/cameras/find/features_list/${userDetails.outlet_code}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("resultdata", result.data);
        setSafetyFeatures(result.data["safety"]);
        setHygieneCount(result.data["hygiene"].length);
        setQualityCount(result.data["quality"].length);
        setSpin(false);
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        setSpin(false);
        swal({
          title: "Oops!",
          text: "Something went wrong!",
          icon: "error",
          buttons: false,
        });
      });
  };

  return (
    <div className={Hy.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <div className={Hy.topbar}>
        <div className={Hy.leftbar}>
          <div
            className={
              location.pathname === "/hygiene" ? Hy.holderactive : Hy.holder
            }
            onClick={() => navigate("/hygiene")}
          >
            Hygiene ({hygieneCount})
          </div>
          <div
            className={
              location.pathname === "/quality" ? Hy.holderactive : Hy.holder
            }
            onClick={() => navigate("/quality")}
          >
            Quality ({qualityCount})
          </div>
          <div
            className={
              location.pathname === "/safety" ? Hy.holderactive : Hy.holder
            }
            onClick={() => navigate("/safety")}
          >
            Safety ({safetyFeatures.length})
          </div>
        </div>
        <div className={Hy.rightpart}>
          <select
            className={Hy.dayselect}
            onChange={(e) => setInterval(e.target.value)}
          >
            <option selected value="day">
              Today
            </option>
            <option value="yesterday">Yesterday</option>
            <option value="week">Last 7 Days</option>
            <option value="month">Last 30 Days</option>
          </select>
        </div>
      </div>

      {/* <div className={Hy.summarycardsholder}>
        <div className={Hy.carouselcard}>
          <div className={Hy.summarytopbar}>
            <BulbIcon className={Hy.bulbicon} />
            <h4 className={Hy.summaryheading}>Quick Summary</h4>
          </div>
          <h5 className={Hy.quicksummaryinfo}>
            <span className={Hy.boldtext}>Security Team Presence</span> count{" "}
            <span className={Hy.redisights}>increased 109% </span> since last
            week. Improve areas to increase Compliance Score.
          </h5>
        </div>
        <Carousel
          infinite={true}
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          dotListClass="custom-dot-list-style"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          className={Hy.quicksummarycards}
        >
          <div className={Hy.carouselcard}>
            <div className={Hy.summarytopbar}>
              <BulbIcon className={Hy.bulbicon} />
              <h4 className={Hy.summaryheading}>Quick Summary</h4>
            </div>
            <h5 className={Hy.quicksummaryinfo}>
              Hygiene Index <span className={Hy.improved}>improved</span> since
              Yesterday. <span className={Hy.boldtext}>Sanitaion </span>Score
              <span className={Hy.reduced}> reduced</span> since Yesterday.
            </h5>
          </div>
          <div className={Hy.carouselcard}>
            <div className={Hy.summarytopbar}>
              <BulbIcon className={Hy.bulbicon} />
              <h4 className={Hy.summaryheading}>Quick Summary</h4>
            </div>
            <h5 className={Hy.quicksummaryinfo}>
              Hygiene Index <span className={Hy.improved}>improved</span> since
              Yesterday. <span className={Hy.boldtext}>Sanitaion </span>Score
              <span className={Hy.reduced}> reduced</span> since Yesterday.
            </h5>
          </div>
        </Carousel>
        <div className={Hy.datainsights}>
          <div className={Hy.summarytopbar}>
            <AnalyticsIcon />
            <h4 className={Hy.summaryheading}>Data Insights</h4>
          </div>
          <br />
          <h3 className={Hy.quicksummaryinfo}>
            <span className={Hy.boldtext}>People Gathering</span> Count reduced.
            Lower than Monthly Average.
          </h3>
          <div className={Hy.buttoninsightsholder}>
            <button className={Hy.insightsbutton}>
              Yesterday: <span className={Hy.orangetext}>3.5</span>
            </button>
            <button className={Hy.insightsbutton}>
              Today: <span className={Hy.greentext}>4</span>
            </button>
          </div>
          <div className={Hy.buttoninsightsholder}>
            <button className={Hy.insightsbutton}>
              Weekly Avg: <span className={Hy.greentext}>4</span>
            </button>
            <button className={Hy.insightsbutton}>
              Montly Avg: <span className={Hy.greentext}>4.5</span>
            </button>
          </div>
        </div>
      </div> */}
      <div className={Hy.cardsholder}>
        {/* Cards for test responsiveness */}
        {/* <SocialDistancingCard />
        <PeopleGatheringCard />
        <SecurityTeamPresenceCard />
        <IntrutionDetectionCard />
        <FireAndSmokeDetectionCard />
        <ViolenceDetectionCard />
        <CctvTamperingCard />
        <AnprCard />
        <ElectricityWastageCard /> */}
        {safetyFeatures.length !== 0 ? (
          <React.Fragment>
            {safetyFeatures.includes("CS.SD") ? (
              <SocialDistancingCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {safetyFeatures.includes("CS.PG") ? (
              <PeopleGatheringCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {safetyFeatures.includes("ST.STP") ? (
              <SecurityTeamPresenceCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {safetyFeatures.includes("ST.ID") ? (
              <IntrutionDetectionCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {safetyFeatures.includes("ST.FD") ? (
              <FireAndSmokeDetectionCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {safetyFeatures.includes("SAD.VD") ? (
              <ViolenceDetectionCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {safetyFeatures.includes("TA.CCTV") ? (
              <CctvTamperingCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            {safetyFeatures.includes("DL.NPd") ? (
              <AnprCard interval={interval} setInterval={setInterval} />
            ) : null}
            {safetyFeatures.includes("EM.EWD") ? (
              <ElectricityWastageCard
                interval={interval}
                setInterval={setInterval}
              />
            ) : null}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/selectcameras")}
            >
              <AddFeatureCard />
            </div>
          </React.Fragment>
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/selectcameras")}
          >
            <AddFeatureCard />
          </div>
        )}
      </div>
    </div>
  );
};

export default Safety;
