// Dependencies imports
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

// Local imports
import Close from "../../../../../Assets/icons/blueicon.png";
import BarChart from "./footfallgraph";
import { authData } from "../../../../../redux/auth/authSlice";
import ImagePlaceholder from "../../../../../Assets/images/imageplaceholder.png";
import ImageViewModal from "../roomoccupancy/imageviewmodal";

// Style imports
import Graph from "../billingcounterunmanned/graphstyles.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    "& .super-app-theme-header": {
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
      flex: 1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
    width: "100%",
  },
}));

const FoolFallModal = (props) => {
  const { handleClose, count, rushHour, interval, setInterval } = props;
  const [selectedNav, setSelectedNav] = useState("graph");
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [images, setImages] = useState([]);
  let userDetails = useSelector(authData);
  const [modalImage, setModalImage] = useState();
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();
  const [spin, setSpin] = useState(true);

  useEffect(() => {
    getGraphData();
    //eslint-disable-next-line
  }, [interval]);

  //   API to get graph and logs response
  const getGraphData = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/people_count/aggregate_list/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log("counter manned response", result.data);
        if (result.data["result"] !== 0) {
          formatGraphData(result.data["result"]);
        }

        getDataGridData();
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  //   Formating response to get graph data
  const formatGraphData = (values) => {
    let labels = [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ];
    let events = [];

    for (const [key, value] of Object.entries(values)) {
      if (value.length !== 0) {
        events.push(value[0]["people_count"]);
      } else {
        events.push(0);
      }
    }
    console.log("labels", labels);
    console.log("events", events);
    setLabels(labels);
    setData(events);
  };

  //   API to get graph and logs response
  const getDataGridData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/people_count/aggregate_logs/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        if (result.data["result"].length !== 0) {
          tableViewConvertion(result.data["result"]);
        }
        setSpin(false);
      })
      .catch(function (error) {
        setSpin(false);
      });
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const tableViewConvertion = (value) => {
    let trows = [];
    let timages = [];

    if (value.length > 0) {
      for (let i = value.length - 1; i >= 0; i--) {
        if (value[i]["events"].length > 0) {
          for (let k = value[i]["events"].length - 1; k >= 0; k--) {
            let obj = {};
            let tobj = {};
            tobj.screenshot = value[i]["events"][k]["screenshot"];
            tobj.duration = value[i]["events"][k]["duration"];
            tobj.timestamp = `${moment
              .unix(value[i]["events"][k]["timestamp"])
              .format("LT")}`;
            tobj.cameranumber = value[i]["roi_name"];
            obj["id"] = trows.length + 1;
            let todayDate = `${value[i]["timestamp"]["day"]}-${value[i]["timestamp"]["month"]}-${value[i]["timestamp"]["year"]}`;
            todayDate = todayDate.split("-");
            obj["timeframe"] = `${moment
              .unix(value[i]["events"][k]["timestamp"])
              .format("LLL")}`;
            obj["camera"] = value[i]["roi_name"];
            obj["status"] = `${String(value[i]["events"][k]["people_count"])}`;
            obj["image"] = value[i]["events"][k]["screenshot"];
            console.log("object", obj);
            trows.push(obj);
            timages.push(tobj);
            console.log("pushed");
          }
        }
      }
    }

    const tcolumns = [
      {
        field: "timeframe",
        headerName: "TIMEFRAME",
        flex: 1,
        headerClassName: "super-app-theme-header",
      },
      {
        field: "camera",
        headerName: "AREA NAME",
        flex: 1,
        headerClassName: "super-app-theme-header",
      },
      {
        field: "status",
        headerName: "FOOTFALL COUNT",
        flex: 1,
        headerClassName: "super-app-theme-header",
      },
      {
        field: "image",
        headerName: "IMAGES",
        flex: 0.5,
        headerClassName: "super-app-theme-header",

        renderCell: (params) => (
          <div
            className={Graph.image}
            onClick={() => {
              setModalImage(params.value);
              console.log(params.value);
              handleModalOpen();
            }}
          >
            <img
              src={ImagePlaceholder}
              alt="imageholder"
              className={Graph.imageplaceholder}
            />
          </div>
        ),
      },
    ];
    if (interval !== "day") {
      tcolumns.pop();
    }

    setRows(trows);
    setColumns(tcolumns);
    setImages(timages);
    setSpin(false);
  };

  const GraphComponent = () => {
    return (
      <div className={Graph.graphcontainer}>
        <div className={Graph.topcountholder}>
          <div className={Graph.dataholder}>
            <h4 className={Graph.data}>
              Count: <span className={Graph.bolddata}>{count}</span>
            </h4>
          </div>
          <div className={Graph.dataholder}>
            <h4 className={Graph.data}>
              Rush Hour:{" "}
              <span className={Graph.bolddata}>
                {rushHour !== null ? rushHour : "00"}
                {":00"}- {rushHour !== null ? rushHour + 1 : "00"}
                {":00"}
              </span>
            </h4>
          </div>
        </div>
        <div className={Graph.graphholder}>
          <BarChart labels={labels} eventsData={data} />
        </div>
      </div>
    );
  };

  return (
    <div className={Graph.maincontainer}>
      <Modal
        open={openModal}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={openModal}>
          <ImageViewModal
            handleModalClose={handleModalClose}
            modalImage={modalImage}
          />
        </Fade>
      </Modal>
      <div className={Graph.topbar}>
        <h3 className={Graph.heading}>Foot Fall</h3>
        <div className={Graph.topnavigation}>
          <div
            className={
              selectedNav === "graph"
                ? Graph.navitemholderactive
                : Graph.navitemholder
            }
            onClick={() => setSelectedNav("graph")}
          >
            <h4 className={Graph.navitem}>Graphs</h4>
          </div>
          <div
            className={
              selectedNav === "logs"
                ? Graph.navitemholderactive
                : Graph.navitemholder
            }
            onClick={() => setSelectedNav("logs")}
          >
            <h4 className={Graph.navitem}>Logs</h4>
          </div>
          {interval !== "day" ? null : (
            <div
              className={
                selectedNav === "images"
                  ? Graph.navitemholderactive
                  : Graph.navitemholder
              }
              onClick={() => setSelectedNav("images")}
            >
              <h4 className={Graph.navitem}>Images</h4>
            </div>
          )}
        </div>
        <div className={Graph.rightselection}>
          <select
            className={Graph.dayselect}
            value={interval}
            onChange={(e) => {
              if (selectedNav === "images" && interval === "day") {
                setSelectedNav("logs");
              }
              setRows([]);
              setColumns([]);
              setImages([]);
              setInterval(e.target.value);
            }}
          >
            <option selected value="day">
              Today
            </option>
            <option value="yesterday">Yesterday</option>
            <option value="week">Last 7 Days</option>
            <option value="month">Last 30 Days</option>
          </select>
          <img
            src={Close}
            alt="closeimage"
            className={Graph.closeimage}
            onClick={() => handleClose()}
          />
        </div>
      </div>
      <div className={Graph.middlebar}>
        {selectedNav === "graph" ? (
          spin === false ? (
            <GraphComponent />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rect" width="100%" height={500} />{" "}
            </div>
          )
        ) : selectedNav === "logs" ? (
          spin === false ? (
            <div style={{ height: "60vh", width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                className={classes[".MuiDataGrid-root"]}
                rowHeight={100}
                disableSelectionOnClick
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rect" width="100%" height={500} />{" "}
            </div>
          )
        ) : (
          <div className={Graph.carouselholder}>
            {images.length !== 0 ? (
              <Carousel dynamicHeight={true} width={"60%"}>
                {images.slice(0, 10).map((value, i) => {
                  return (
                    <div key={i}>
                      <img
                        src={value.screenshot}
                        alt="eventimage"
                        className={Graph.eventimage}
                      />
                      <p className="legend">
                        Action: Foot Fall
                        <br />
                        Area: {value.cameranumber}
                        <br />
                        Timestamp: {value.timestamp}
                        <br />
                        {/* Duration: {value.duration} mins */}
                      </p>
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              <h4>Nothing to show here</h4>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FoolFallModal;
