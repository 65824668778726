// Dependencies imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import Logo from "../../../Assets/logo/logo.png";
import { ReactComponent as SignupImage } from "../../../Assets/images/signup.svg";
import NameIcon from "../../../Assets/images/nameicon.png";
import EmailIcon from "../../../Assets/images/emailicon.png";
import PasswordIcon from "../../../Assets/images/passwordicon.png";
import CompanyIcon from "../../../Assets/images/cnameicon.png";
import HomeLocationIcon from "../../../Assets/images/homelocationicon.png";
import { stateUpdate } from "../../../redux/auth/authSlice";

// Style imports
import Sign from "./signup.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

// Start of main component
const Signup = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [password, setPassword] = useState("");
  const [passErr, setPassErr] = useState("");
  const [reEnterPass, setReEnterPass] = useState("");
  const [reEnterPassErr, setReEnterPassErr] = useState("");
  const [comName, setComName] = useState("");
  const [comNameErr, setComNameErr] = useState("");
  const [location, setLocation] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();

  let userDetails = {};
  const dispatch = useDispatch();

  // Signup form validation
  const validateForm = () => {
    let formIsValid = true;
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    setNameErr("");
    setEmailErr("");
    setPassErr("");
    setComNameErr("");
    setLocationErr("");

    if (name === "" || name === null || name.trim() === "") {
      formIsValid = false;
      setNameErr("Name cannot be empty");
      return;
    }
    if (email === "" || email === null || email.trim() === "") {
      formIsValid = false;
      setEmailErr("Email cannot be empty");
      return;
    }
    if (email !== "") {
      if (regex.test(email) === false) {
        formIsValid = false;
        setEmailErr("Entered email is not valid");
        return;
      }
    }
    if (password === "" || password === null || password.trim() === "") {
      formIsValid = false;
      setPassErr("Password cannot be empty");
      return;
    }
    if (
      reEnterPass === "" ||
      reEnterPass === null ||
      reEnterPass.trim() === ""
    ) {
      formIsValid = false;
      setReEnterPassErr("Reenter Password cannot be empty");
      return;
    }
    if (reEnterPass !== password) {
      formIsValid = false;
      setReEnterPass("Passord is not matching");
      return;
    }

    if (comName === "" || comName === null || comName.trim() === "") {
      formIsValid = false;
      setComNameErr("Company name cannot be empty");
      return;
    }
    if (location === "" || location === null || location.trim() === "") {
      formIsValid = false;
      setLocationErr("HQ Location cannot be empty");
      return;
    }
    if (formIsValid === true) {
      console.log("form is valid");
      sendUserData();

      return;
    }
  };

  //API to send user details
  const sendUserData = () => {
    setSpin(true);
    var data = qs.stringify({
      name: name.toString(),
      email: email.toString(),
      password: password.toString(),
      role: "admin",
    });

    var config = {
      method: "POST",
      url: "https://api.oc3.oureye.ai/users/new",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log(result);
        userDetails.username = result.data.name;
        userDetails.email = result.data.email;
        console.log(JSON.stringify("result data", result.data));
        // navigate("/onboarding");
        setSpin(false);
        companyData();
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        if (error.response.status === 400) {
          setSpin(false);
          swal({
            title: error.response.data.detail,
            text: "   ",
            icon: "error",
            buttons: false,
          });
        }
      });
  };

  // Api to send Company details
  const companyData = () => {
    setSpin(true);
    var data = qs.stringify({
      comp_name: comName.toString(),
      location: location.toString(),
      onboarded_by: email.toString(),
    });

    var config = {
      method: "POST",
      url: "https://api.oc3.oureye.ai/onboard/company",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log(JSON.stringify("result data", result.data));
        userDetails.company_code = result.data.company_code;
        dispatch(stateUpdate(userDetails));
        setSpin(false);
        getOnboardingState();
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        if (error.response.status === 400) {
          setSpin(false);
          swal({
            title: error.response.data.detail,
            text: "   ",
            icon: "error",
            buttons: false,
          });
        }
      });
  };

  const getOnboardingState = () => {
    setSpin(true);
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/onboard/onboarding_state/${userDetails.email.toString()}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        console.log(result.data);
        let userData = { ...userDetails };
        userData.onboarding_state = result.data;
        dispatch(stateUpdate(userData));
        setSpin(false);
        navigate("/outletdetails");
      })
      .catch(function (error) {
        console.log("error first", error.response.status);
        setSpin(false);
      });
  };

  return (
    <div className={Sign.maincontainer}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      {/* Left Side of the screen */}
      <div className={Sign.leftside}>
        <img src={Logo} alt="oureyelogo" className={Sign.logoimage} />
        <div className={Sign.holder}>
          <SignupImage className={Sign.signupicon} />
        </div>
      </div>

      {/* Right side of the screen */}
      <div className={Sign.rightside}>
        <h3 className={Sign.heading}>Create Account</h3>
        <div className={Sign.formholder}>
          <div className={Sign.inputholder}>
            <img src={NameIcon} alt="nameicon" className={Sign.fieldimage} />
            <input
              type="text"
              placeholder="Full Name"
              className={Sign.inputfield}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <h4 className={Sign.errormessage}>{nameErr}</h4>
          <div className={Sign.inputholder}>
            <img src={EmailIcon} alt="emailicon" className={Sign.fieldimage} />
            <input
              type="email"
              placeholder="Email"
              className={Sign.inputfield}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <h4 className={Sign.errormessage}>{emailErr}</h4>
          <div className={Sign.inputholder}>
            <img
              src={PasswordIcon}
              alt="passwordicon"
              className={Sign.fieldimage}
            />
            <input
              type="password"
              placeholder="Password"
              className={Sign.inputfield}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <h4 className={Sign.errormessage}>{passErr}</h4>
          <div className={Sign.inputholder}>
            <img
              src={PasswordIcon}
              alt="passwordicon"
              className={Sign.fieldimage}
            />
            <input
              type="password"
              placeholder="Re-Enter Password"
              className={Sign.inputfield}
              value={reEnterPass}
              onChange={(e) => {
                setReEnterPass(e.target.value);
                if (e.target.value !== password) {
                  setReEnterPassErr("Password is not matching");
                } else {
                  setReEnterPassErr("");
                }
              }}
            />
          </div>
          <h4
            className={
              reEnterPass === password ? Sign.correctmessage : Sign.errormessage
            }
          >
            {reEnterPassErr}
          </h4>
          <div className={Sign.inputholder}>
            <img
              src={CompanyIcon}
              alt="companyicon"
              className={Sign.fieldimage}
            />
            <input
              type="text"
              placeholder="Company Name"
              className={Sign.inputfield}
              value={comName}
              onChange={(e) => setComName(e.target.value)}
            />
          </div>
          <h4 className={Sign.errormessage}>{comNameErr}</h4>
          <div className={Sign.inputholder}>
            <img
              src={HomeLocationIcon}
              alt="locatoinicon"
              className={Sign.fieldimage}
            />
            <input
              type="text"
              placeholder="HQ Location"
              className={Sign.inputfield}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <h4 className={Sign.errormessage}>{locationErr}</h4>
          <button className={Sign.button} onClick={() => validateForm()}>
            Next
          </button>
          <div className={Sign.nouserholder}>
            <h4 className={Sign.nouser}>
              Already an user?{" "}
              <span
                className={Sign.signuplink}
                onClick={() => navigate("/login")}
              >
                Login
              </span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
