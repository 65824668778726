// Dependencies imports
import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

// Local imports
import LogoWhite from "../../../Assets/logo/logowhite.png";
import { ReactComponent as RightChevronWhite } from "../../../Assets/icons/rightchevronwhite.svg";
import { ReactComponent as RightChevronBlue } from "../../../Assets/icons/rightchevronblue.svg";
import { hyginefeatures } from "./featuredetails";
import { safetyfeatures } from "./featuredetails";
import { qualityfeatures } from "./featuredetails";
import FeatureDescription from "./featuredescription";
import RoiMaker from "./roimaker";
import Scheduling from "./scheduling";
import AddingSchedule from "./addingschedule";
import SyncDemo from "./syncdemo";
import { stateUpdate, authData } from "../../../redux/auth/authSlice";

// Style imports
import Select from "./selectfeatures.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

// Start of SelectFeatures Component
const SelectFeatures = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [mappingState, setMappingState] = useState(hyginefeatures);
  const [featureName, setFeatureName] = useState("");
  const [featureDescription, setFeatureDescription] = useState("");
  const [featureImage, setFeatureImage] = useState();
  const [openRoi, setOpenRoi] = useState(false); //Opening Roi modal
  const [openSchedule, setOpenSchedule] = useState(false); //Opening Schedule modal
  const [openFeatureDescription, setOpenFeatureDescription] = useState(false); //Opening Feature description modal
  const [openAddSchedule, setOpenAddSchedule] = useState(false); //Opening add Schedule modal
  const [openSyncDemo, setOpenSyncDemo] = useState(false); //Opening sync demo modal
  const classes = useStyles();
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  useEffect(() => {
    let userData = { ...userDetails };
    console.log("userDetails", userDetails);
    delete userData.roi_coordinates;
    delete userData.area_selected;
    dispatch(stateUpdate(userData));
    //eslint-disable-next-line
  }, []);

  // function to open the Roi Modal
  const handleOpenRoi = () => {
    setOpenRoi(true);
  };

  // function to close the Roi Modal
  const handleCloseRoi = () => {
    setOpenRoi(false);
  };

  // function to open the Scheduling Modal
  const handleOpenSchedule = () => {
    setOpenSchedule(true);
  };

  // function to close the Scheduling Modal
  const handleCloseSchedule = () => {
    setOpenSchedule(false);
  };

  // function to open the Feature Description Modal
  const handleOpenFeatureDescription = () => {
    setOpenFeatureDescription(true);
  };

  // Function to close the Feature Description Modal
  const handleCloseFeatureDescription = () => {
    setOpenFeatureDescription(false);
  };

  // Function to open the add schedule modal
  const handleOpenAddSchedule = () => {
    setOpenAddSchedule(true);
  };

  // Function to close the add schedule modal
  const handleCloseAddSchedule = () => {
    setOpenAddSchedule(false);
  };

  // Function to open the sync demo modal
  const handleOpenSyncDemo = () => {
    setOpenSyncDemo(true);
  };

  // Function to close the sync demo modal
  const handleCloseSyncDemo = () => {
    setOpenSyncDemo(false);
  };

  // Feature card component
  const FeatureCard = (props) => {
    const {
      featureicon,
      featurename,
      featuredescription,
      featureImage,
      featureCode,
    } = props;
    return (
      <div className={Select.featuremainholder}>
        <div className={Select.lefticons}>
          <div className={Select.featureiconholder}>
            <div className={Select.iconholder}>
              <img
                src={featureicon}
                alt="feature icon"
                className={Select.featureicon}
              />
            </div>
            <h3 className={Select.featureheading}>{featurename}</h3>
          </div>
        </div>
        <div className={Select.rightaddbutton}>
          <button
            className={Select.addbutton}
            onClick={() => {
              setFeatureName(featurename);
              setFeatureDescription(featuredescription);
              setFeatureImage(featureImage);
              let userData = { ...userDetails };
              userData.feature_code = featureCode;
              dispatch(stateUpdate(userData));
              handleOpenFeatureDescription();
            }}
          >
            Add
          </button>
        </div>
      </div>
    );
  };

  // Start of return of the main component
  return (
    <div className={Select.maincontainer}>
      <Modal
        open={openFeatureDescription}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={openFeatureDescription}>
          <FeatureDescription
            handleCloseFeatureDescription={handleCloseFeatureDescription}
            handleOpenRoi={handleOpenRoi}
            featureName={featureName}
            featureDescription={featureDescription}
            featureImage={featureImage}
          />
        </Fade>
      </Modal>
      <Modal
        open={openRoi}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={openRoi}>
          <RoiMaker
            handleCloseRoi={handleCloseRoi}
            handleOpenAddSchedule={handleOpenAddSchedule}
          />
        </Fade>
      </Modal>
      <Modal
        open={openAddSchedule}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={openAddSchedule}>
          <AddingSchedule
            handleCloseAddSchedule={handleCloseAddSchedule}
            handleOpenSchedule={handleOpenSchedule}
            handleOpenSyncDemo={handleOpenSyncDemo}
          />
        </Fade>
      </Modal>
      <Modal
        open={openSchedule}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={openSchedule}>
          <Scheduling
            handleCloseSchedule={handleCloseSchedule}
            handleOpenAddSchedule={handleOpenAddSchedule}
          />
        </Fade>
      </Modal>
      <Modal
        open={openSyncDemo}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={openSyncDemo}>
          <SyncDemo handleCloseSyncDemo={handleCloseSyncDemo} />
        </Fade>
      </Modal>
      <div className={Select.header}>
        <div className={Select.leftheader}>
          <img src={LogoWhite} alt="oureyelogo" className={Select.logoimage} />
        </div>
        <div className={Select.rightheader}>
          <h3 className={Select.username}>Hello {userDetails.username},</h3>
        </div>
      </div>
      <div className={Select.bodylayout}>
        <div className={Select.leftnavigation}>
          <h4 className={Select.navigationheading}>Categories:</h4>
          <div
            className={
              activeIndex === 0
                ? Select.navigationholderactive
                : Select.navigationholder
            }
            onClick={() => {
              setActiveIndex(0);
              setMappingState(hyginefeatures);
            }}
          >
            <h5
              className={
                activeIndex === 0 ? Select.navtitleactive : Select.navtitle
              }
              onClick={() => {
                setActiveIndex(0);
                setMappingState(hyginefeatures);
              }}
            >
              Hygine
            </h5>
            {activeIndex === 0 ? (
              <RightChevronBlue className={Select.righticon} />
            ) : (
              <RightChevronWhite className={Select.righticon} />
            )}
          </div>
          <div
            className={
              activeIndex === 1
                ? Select.navigationholderactive
                : Select.navigationholder
            }
            onClick={() => {
              setActiveIndex(1);
              setMappingState(qualityfeatures);
            }}
          >
            <h5
              className={
                activeIndex === 1 ? Select.navtitleactive : Select.navtitle
              }
              onClick={() => {
                setActiveIndex(1);
                setMappingState(qualityfeatures);
              }}
            >
              Quality
            </h5>
            {activeIndex === 1 ? (
              <RightChevronBlue className={Select.righticon} />
            ) : (
              <RightChevronWhite className={Select.righticon} />
            )}
          </div>
          <div
            className={
              activeIndex === 2
                ? Select.navigationholderactive
                : Select.navigationholder
            }
            onClick={() => {
              setActiveIndex(2);
              setMappingState(safetyfeatures);
            }}
          >
            <h5
              className={
                activeIndex === 2 ? Select.navtitleactive : Select.navtitle
              }
              onClick={() => {
                setActiveIndex(2);
                setMappingState(safetyfeatures);
              }}
            >
              Safety
            </h5>
            {activeIndex === 2 ? (
              <RightChevronBlue className={Select.righticon} />
            ) : (
              <RightChevronWhite className={Select.righticon} />
            )}
          </div>
        </div>
        <div className={Select.rightfeatures}>
          <h3 className={Select.headingright}>
            Select features you want to add:
          </h3>
          {mappingState.map((value, i) => {
            return (
              <div className={Select.featurecardholder} key={i}>
                <FeatureCard
                  featureicon={value.featureicon}
                  featurename={value.featurename}
                  featuredescription={value.featuredescription}
                  featureImage={value.featureimage}
                  featureCode={value.featurecode}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectFeatures;
