// Dependencies imports
import React from "react";

// Local imports
import SideNav from "../sidenav/sidenav";
import Header from "../headers/header";
import Safety from "../Components/Screens/DashboardScreens/safety/safety";

// Style imports
import Lay from "./layout.module.scss";

const SafetyLayout = () => {
  return (
    <div className={Lay.maincontainer}>
      <Header />
      <div className={Lay.nextcontainer}>
        <SideNav />
        <Safety />
      </div>
    </div>
  );
};

export default SafetyLayout;
