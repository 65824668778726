// Dependencies imports
import React from "react";

// Local imports
import { ReactComponent as Close } from "../../../Assets/images/close.svg";
import QrCode from "../../../Assets/images/qrcode.png";
// Style imports
import Down from "./downloadappmodal.module.scss";

const DownloadApp = (props) => {
  const { handleClose } = props;

  return (
    <div className={Down.modalcontainer}>
      <div className={Down.closeholder}>
        <Close className={Down.closeicon} onClick={() => handleClose()} />
      </div>
      <img src={QrCode} alt="downladicon" className={Down.image} />
      <h3 className={Down.headingbelow}>Profile Setup is Done!</h3>
      <p className={Down.phrase}>
        In order to move on with the onboarding process, please scan the QR code
        to download the app.
      </p>
    </div>
  );
};

export default DownloadApp;
