// Dependencies imports
import React, { useState, useEffect } from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import InfoIcon from "../../../../../Assets/icons/infoicon.png";
import { authData } from "../../../../../redux/auth/authSlice";
import CustomerSatisfactionModal from "./customersatisfactionmodal";

// Style imports
import Card from "../../hygiene/cardstyle.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const CustomerSatisfactionCard = (props) => {
  const { interval, setInterval } = props;
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [percent, setPercent] = useState(0);
  const [happyPercent, setHappyPercent] = useState(0);
  const [normalPercent, setNormalPercent] = useState(0);
  const [sadPercent, setSadPercent] = useState(0);

  useEffect(() => {
    getCardData();
    //eslint-disable-next-line
  }, [interval]);

  const getCardData = () => {
    var config = {
      method: "GET",
      url: `https://api.oc3.oureye.ai/customer_emotions/aggregate/${userDetails.outlet_code}/${interval}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        if (
          result.data["HAPPY"] +
            result.data["SURPRISED"] +
            result.data["CALM"] +
            result.data["ANGRY"] +
            result.data["CONFUSED"] +
            result.data["DISGUSTED"] +
            result.data["FEAR"] +
            result.data["SAD"] !==
          0
        ) {
          let temppercent =
            ((result.data["HAPPY"] +
              result.data["SURPRISED"] +
              result.data["CALM"]) /
              (result.data["HAPPY"] +
                result.data["SURPRISED"] +
                result.data["CALM"] +
                result.data["ANGRY"] +
                result.data["CONFUSED"] +
                result.data["DISGUSTED"] +
                result.data["FEAR"] +
                result.data["SAD"])) *
            100;
          setPercent(temppercent);

          setHappyPercent(temppercent);
          let normalcent =
            ((result.data["CONFUSED"] + result.data["DISGUSTED"]) /
              (result.data["HAPPY"] +
                result.data["SURPRISED"] +
                result.data["CALM"] +
                result.data["ANGRY"] +
                result.data["CONFUSED"] +
                result.data["DISGUSTED"] +
                result.data["FEAR"] +
                result.data["SAD"])) *
            100;
          setNormalPercent(normalcent);

          let sadcent =
            ((result.data["ANGRY"] + result.data["FEAR"] + result.data["SAD"]) /
              (result.data["HAPPY"] +
                result.data["SURPRISED"] +
                result.data["CALM"] +
                result.data["ANGRY"] +
                result.data["CONFUSED"] +
                result.data["DISGUSTED"] +
                result.data["FEAR"] +
                result.data["SAD"])) *
            100;
          setSadPercent(sadcent);
        } else {
          setPercent(0);
          setHappyPercent(0);
          setNormalPercent(0);
          setSadPercent(0);
        }
      })
      .catch(function (error) {});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={Card.mainbigcontainer}>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={classes.modal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <CustomerSatisfactionModal
            handleClose={handleClose}
            percent={percent}
            happyPercent={happyPercent}
            normalPercent={normalPercent}
            sadPercent={sadPercent}
            interval={interval}
            setInterval={setInterval}
          />
        </Fade>
      </Modal>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Customer Satisfaction</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      <div className={Card.semicircleprogressholder}>
        <SemiCircleProgressBar
          percentage={percent.toFixed(2)}
          showPercentValue
          diameter={120}
          stroke={
            percent > 60 ? "#2BA65A" : percent < 30 ? "#EE4143" : "#FBCD57"
          }
        />
      </div>
      <button className={Card.learnmore} onClick={() => handleOpen()}>
        Learn More
      </button>
    </div>
  );
};

export default CustomerSatisfactionCard;
