// Dependencies imports
import React from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";

// Local imports
import InfoIcon from "../../../../Assets/icons/infoicon.png";
import UnderTraining from "../../../../Assets/icons/undertraining.gif";

// Style imports
import Card from "../hygiene/cardstyle.module.scss";

const CustomerConversionCard = () => {
  return (
    <div className={Card.mainbigcontainer}>
      <div className={Card.topinfo}>
        <h4 className={Card.featurename}>Customer Conversion</h4>
        <img className={Card.infoicon} src={InfoIcon} alt="infoicon" />
      </div>
      {/* <div className={Card.semicircleprogressholder}>
        <SemiCircleProgressBar
          percentage={75}
          showPercentValue
          diameter={125}
          stroke="#2BA65A"
        />
      </div>
      <div className={Card.middlecardholder}>
        <button className={Card.learnmore}>Learn More</button>
      </div> */}
      <div className={Card.underprogressholder}>
        <img
          src={UnderTraining}
          className={Card.undertraininggif}
          alt="undertraininggif"
        />
        <h4 className={Card.underprogress}>Training under progress</h4>
      </div>
    </div>
  );
};

export default CustomerConversionCard;
