// Dependencies imports
import React, { useState } from "react";
import qs from "qs";
import axios from "axios";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Local imports
import { authData } from "../../../redux/auth/authSlice";

// Style imports
import Sch from "./scheduling.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
}));

const Scheduling = (props) => {
  const { handleCloseSchedule, handleOpenAddSchedule } = props;
  let userDetails = useSelector(authData);
  const [spin, setSpin] = useState(false);
  const [scheduleName, setScheduleName] = useState("");
  const [scheduleNameErr, setScheduleNameErr] = useState("");
  const [startTime, setStartTime] = useState("09:00");
  const [startTimeErr, setStartTimeErr] = useState("");
  const [endTime, setEndTime] = useState("23:00");
  const classes = useStyles();
  const [endTimeErr, setEndTimeErr] = useState("");
  const [repeatingDays, setRepeatingDays] = useState([]);
  const [repeatingDaysErr, setRepeatingDaysErr] = useState("");
  const [sun, setSun] = useState(false);
  const [mon, setMon] = useState(false);
  const [tu, setTu] = useState(false);
  const [wed, setWed] = useState(false);
  const [th, setTh] = useState(false);
  const [fri, setFri] = useState(false);
  const [sat, setSat] = useState(false);

  //function to push days if the days or not selected
  const handleFalseDays = (e) => {
    repeatingDays.push(parseInt(e));
    setRepeatingDays(repeatingDays);
  };

  // function to remove days if the day is already slected
  const handleTrueDays = (e) => {
    let index = repeatingDays.indexOf(parseInt(e));
    repeatingDays.splice(index, 1);
    setRepeatingDays(repeatingDays);
  };

  // function to validate form
  const validateForm = () => {
    let formIsValid = true;
    setScheduleNameErr("");
    setStartTimeErr("");
    setEndTimeErr("");
    setRepeatingDaysErr("");

    if (
      scheduleName === "" ||
      scheduleName === null ||
      scheduleName.trim() === ""
    ) {
      formIsValid = false;
      setScheduleNameErr("Schedule name cannot be empty");
      return;
    }
    if (startTime === "" || startTime === null || startTime.trim() === "") {
      formIsValid = false;
      setStartTimeErr("Please select start time");
      return;
    }
    if (endTime === "" || endTime === null || endTime.trim() === "") {
      formIsValid = false;
      setEndTimeErr("Please select end time");
      return;
    }
    if (endTime === startTime) {
      formIsValid = false;
      setEndTimeErr("End time should be greater than start time");
      return;
    }
    if (repeatingDays.length === 0) {
      formIsValid = false;
      setRepeatingDaysErr("Please select atleast one day");
      return;
    }
    if (formIsValid === true) {
      console.log("form is valid");
      console.log("repeating days", repeatingDays);
      let obj = {};
      obj["repeating_days"] = repeatingDays;
      console.log("object", obj);
      sendTemplate(obj);

      return;
    }
  };

  //API to send user details
  const sendTemplate = (obj) => {
    setSpin(true);
    var data = qs.stringify({
      company_code: userDetails.company_code.toString(),
      schedule_name: scheduleName.toString(),
      start_time: startTime.toString(),
      end_time: endTime.toString(),
      repeating_days: JSON.stringify(obj),
    });

    console.log("data", JSON.stringify(data));
    var config = {
      method: "POST",
      url: "https://api.oc3.oureye.ai/onboard/feature_schedule_template",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        console.log(result);
        setSpin(false);
        handleCloseSchedule();
        handleOpenAddSchedule();
      })
      .catch(function (error) {
        setSpin(false);
        console.log("error first", error.response.status);
        if (error.response.status === 400) {
          swal({
            title: error.response.data.detail,
            text: "   ",
            icon: "error",
            buttons: false,
          });
        }
      });
  };

  return (
    <div className={Sch.mainmodal}>
      <Backdrop className={classes.backdrop} open={spin}>
        <CircularProgress style={{ color: "#226bf6" }} />
      </Backdrop>
      <h3 className={Sch.heading}>New Schedule:</h3>
      <div className={Sch.formholder}>
        <input
          type="text"
          className={Sch.namebox}
          placeholder="Name your Schedule"
          onChange={(e) => setScheduleName(e.target.value)}
        />
        <h4 className={Sch.errormessage}>{scheduleNameErr}</h4>
        <div className={Sch.timeselection}>
          <h4 className={Sch.inputlabel}>Select Scheduling time:</h4>
          <div className={Sch.timeholder}>
            <div className={Sch.lefttimeholder}>
              <input
                className={Sch.selecttime}
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
              <h4 className={Sch.errormessage}>{startTimeErr}</h4>
            </div>
            <div className={Sch.righttimeholder}>
              <input
                className={Sch.selecttime}
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
              <h4 className={Sch.errormessage}>{endTimeErr}</h4>
            </div>
          </div>
        </div>
        <div className={Sch.repeatselection}>
          <h4 className={Sch.inputlabel}>Repeat:</h4>
          <div className={Sch.repeatbuttons}>
            <button
              className={sun ? Sch.weekbuttonactive : Sch.weekbutton}
              onClick={(e) => {
                sun === false ? setSun(true) : setSun(false);
                if (sun === false) {
                  handleFalseDays(e.target.value);
                } else {
                  handleTrueDays(e.target.value);
                }
              }}
              value={6}
            >
              S
            </button>
            <button
              className={mon ? Sch.weekbuttonactive : Sch.weekbutton}
              onClick={(e) => {
                mon === false ? setMon(true) : setMon(false);
                if (mon === false) {
                  handleFalseDays(e.target.value);
                } else {
                  handleTrueDays(e.target.value);
                }
              }}
              value={0}
            >
              M
            </button>
            <button
              className={tu ? Sch.weekbuttonactive : Sch.weekbutton}
              onClick={(e) => {
                tu === false ? setTu(true) : setTu(false);
                if (tu === false) {
                  handleFalseDays(e.target.value);
                } else {
                  handleTrueDays(e.target.value);
                }
              }}
              value={1}
            >
              T
            </button>
            <button
              className={wed ? Sch.weekbuttonactive : Sch.weekbutton}
              onClick={(e) => {
                wed === false ? setWed(true) : setWed(false);
                if (wed === false) {
                  handleFalseDays(e.target.value);
                } else {
                  handleTrueDays(e.target.value);
                }
              }}
              value={2}
            >
              W
            </button>
            <button
              className={th ? Sch.weekbuttonactive : Sch.weekbutton}
              onClick={(e) => {
                th === false ? setTh(true) : setTh(false);
                if (th === false) {
                  handleFalseDays(e.target.value);
                } else {
                  handleTrueDays(e.target.value);
                }
              }}
              value={3}
            >
              T
            </button>
            <button
              className={fri ? Sch.weekbuttonactive : Sch.weekbutton}
              onClick={(e) => {
                fri === false ? setFri(true) : setFri(false);
                if (fri === false) {
                  handleFalseDays(e.target.value);
                } else {
                  handleTrueDays(e.target.value);
                }
              }}
              value={4}
            >
              F
            </button>
            <button
              className={sat ? Sch.weekbuttonactive : Sch.weekbutton}
              onClick={(e) => {
                sat === false ? setSat(true) : setSat(false);
                if (sat === false) {
                  handleFalseDays(e.target.value);
                } else {
                  handleTrueDays(e.target.value);
                }
              }}
              value={5}
            >
              S
            </button>
          </div>
          <h4 className={Sch.errormessage}>{repeatingDaysErr}</h4>
        </div>
      </div>
      <div className={Sch.buttonholder}>
        <button
          className={Sch.continuebutton}
          onClick={() => {
            validateForm();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Scheduling;
